import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import Nav from "./components/Nav";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import Login from "./Pages/Login";
import Employee from "./Pages/Employee";
import Vendor from "./Pages/Vendor";
import Orders from "./Pages/Orders";
import Quantity from "./Pages/Quantity";
import Categories from "./Pages/Categroies";
import SubCategories from "./Pages/SubCategories";
import Customer from "./Pages/Customer";
import AddProducts from "./Pages/AddProducts";
import TaskAssignment from "./Pages/TaskAssignment";
import EmployeeTask from "./Pages/EmployeeTask";
import UserView from "./Pages/UserView";
import "./App.css";
import EmployeeAddedProducts from "./Pages/EmployeeAddedProducts";
import Deliveries from "./Pages/Deliveries";
import Accounts from "./Pages/Account";
import Dashboard from "./Pages/Dashboard";
import Areas from "./Pages/Areas";
import Register from "./Pages/Register";
import CheckoutPage from "./Pages/CheckoutPage";
import Expenditure from "./Pages/Expenditure";
import Sales from "./Pages/OtherProductSales";
import Expenditure_Sales from "./Pages/Expenditure_Sales";
import ProofOfDeliveries from "./Pages/ProofOfDeliveries";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [role, setRole] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCartVisible, setCartVisibility] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loginStatus = localStorage.getItem("login") === "true";
    const userRole = localStorage.getItem("role");
    setIsLoggedIn(loginStatus);
    setRole(userRole);
    setLoading(false);
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    const userRole = localStorage.getItem("role");
    setRole(userRole);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const updateCartCount = (quantity) => {
    setCartCount((prevCount) => prevCount + quantity);
  };

  const renderRoutesByRole = () => {
    switch (role) {
      case "admin":
        return (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/employees" element={<Employee />} />
            <Route path="/customers" element={<Customer />} />
            <Route path="/vendor" element={<Vendor />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/products/add" element={<AddProducts />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/masters/categories" element={<Categories />} />
            <Route path="/masters/subcategories" element={<SubCategories />} />
            <Route path="/masters/quantity" element={<Quantity />} />
            <Route path="/areas" element={<Areas />} />
            <Route path="/task-assign/employee" element={<TaskAssignment />} />
            <Route path="/employee/task" element={<EmployeeTask />} />
            <Route
              path="/employee/products/delivery"
              element={<Deliveries />}
            />
            <Route
              path="/employee/products/add"
              element={<EmployeeAddedProducts />}
            />
            <Route
              path="/"
              element={
                <UserView
                  setCartVisibility={setCartVisibility}
                  updateCartCount={updateCartCount}
                />
              }
            />
            <Route path="/expenditures&sales" element={<Expenditure_Sales />} />
            <Route path="/expenditure" element={<Expenditure />} />
            <Route path="/otherSales" element={<Sales />} />
            <Route path="/proofOfDeliveries" element={<ProofOfDeliveries />} />
          </>
        );
      case "employee":
        return (
          <>
            <Route path="/employee/task" element={<EmployeeTask />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/employee/products/delivery"
              element={<Deliveries />}
            />
            <Route
              path="/employee/products/add"
              element={<EmployeeAddedProducts />}
            />
            <Route path="/task-assign/employee" element={<TaskAssignment />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/customers" element={<Customer />} />
          </>
        );
      case "vendor":
        return (
          <>
            <Route path="/vendor" element={<Vendor />} />
            <Route path="/orders" element={<Orders />} />
          </>
        );
      case "onlineUser":
        return (
          <>
            <Route
              path="/"
              element={
                <UserView
                  setCartVisibility={setCartVisibility}
                  updateCartCount={updateCartCount}
                />
              }
            />
          </>
        );
      case "customer":
        return (
          <>
            <Route path="/dashboard" element={<Dashboard />} />;
            <Route path="/customers" element={<Customer />} />;
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Router>
          <Nav
            toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
            isCartVisible={isCartVisible}
            cartCount={cartCount}
          />
          <div>
            {isLoggedIn && (
              <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            )}
            <main
              className={`main-content ${isSidebarOpen ? "sidebar-open" : ""}`}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <UserView
                      setCartVisibility={setCartVisibility}
                      updateCartCount={updateCartCount}
                    />
                  }
                />
                <Route path="/register" element={<Register />} />
                <Route path="/checkout" element={<CheckoutPage />} />

                {!isLoggedIn && (
                  <Route
                    path="/login"
                    element={<Login onLogin={handleLogin} />}
                  />
                )}

                {isLoggedIn && renderRoutesByRole()}

                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </main>
          </div>
        </Router>
      )}
    </div>
  );
};

export default App;
