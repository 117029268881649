import React, { useEffect, useState } from "react";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { MdEdit, MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Employee = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    role: "employee",
    phoneNumber: "",
  });
  const [employees, setEmployees] = useState([]);
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [availableUsername, setAvailableUsername] = useState([]);
  const [usernameAvailability, setUsernameAvailability] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    getUsers();
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && Array.isArray(response) && response.length > 0) {
        const usernames = response.map((user) => user.username);
        setAvailableUsername(usernames);
      }
    } catch (error) {
      console.error("Error in getting users:", error.message);
    }
  };

  const getUsers = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);

      if (response && response.length > 0) {
        const employeeList = response.filter(
          (user) => user.role === "employee"
        );

        setEmployees(employeeList);
      } else {
        console.error("Invalid response structure", response);
      }
    } catch (error) {
      console.error("Error in fetching users:", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "username") {
      if (value.trim() === "") {
        setUsernameAvailability("");
      } else if (availableUsername.includes(value)) {
        setUsernameAvailability("Username is already taken");
      } else {
        setUsernameAvailability("Username is available");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingEmployee) {
        await apiService.putCall(
          `${apiPath.apiUpdateUser}/${editingEmployee.user_id}`,
          formData
        );
      } else {
        const newEmployee = { ...formData };
        await apiService.postCall(apiPath.apiCreateUser, newEmployee);
      }

      getUsers();
      setFormData({
        name: "",
        username: "",
        password: "",
        role: "employee",
        phoneNumber: "",
      });
      setFormVisible(false);
      setEditingEmployee(null);
    } catch (error) {
      console.error("Error in submitting employee:", error.message);
    }
  };

  const handleEdit = (employee) => {
    setFormVisible(true);
    setEditingEmployee(employee);
    setFormData({
      name: employee.name,
      username: employee.username,
      password: employee.password,
      role: employee.role,
      phoneNumber: employee.phoneNumber,
    });
  };

  const handleClose = () => {
    setFormVisible(false);
    setFormData({
      name: "",
      username: "",
      password: "",
      role: "employee",
      phoneNumber: "",
    });
    setEditingEmployee(null);
  };

  const handleDelete = async (user_id) => {
    try {
      await apiService.deleteCall(`${apiPath.apiDeleteUser}/${user_id}`);

      const updatedEmployees = employees.filter(
        (employee) => employee.user_id !== user_id
      );

      setEmployees(updatedEmployees);
    } catch (error) {
      console.error("Error in deletion:", error.message);
    }
  };

  const [passwordVisibility, setPasswordVisibility] = useState(
    employees.map(() => false)
  );

  const togglePasswordVisibility = (index) => {
    const updatedVisibility = [...passwordVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisibility(updatedVisibility);
  };

  return (
    <div>
      <h1>{t("employeeManagement.title")}</h1>
      <button onClick={() => setFormVisible(true)}>
        {t("employeeManagement.addEmployee")}
      </button>

      {formVisible && (
        <div className="cus-form-container">
          <button
            type="button"
            onClick={handleClose}
            className="cus-close-button"
          >
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <div className="cus-form-field">
              <label>{t("employeeManagement.name")}:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="cus-form-field">
              <label>{t("employeeManagement.username")}:</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
              />
              {formData.username.trim() && usernameAvailability && (
                <p
                  className={`cus-username-availability ${
                    usernameAvailability.includes("taken")
                      ? "cus-error"
                      : "cus-success"
                  }`}
                >
                  {usernameAvailability}
                </p>
              )}
            </div>
            <div className="cus-form-field">
              <label>{t("employeeManagement.password")}:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="cus-form-field">
              <label>{t("employeeManagement.phone")}:</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="cus-form-field">
              <label>{t("employeeManagement.role")}:</label>
              <input
                type="text"
                name="role"
                value={formData.role}
                readOnly
                disabled
              />
            </div>
            <button type="submit" className="cus-submit-button">
              {editingEmployee
                ? t("employeeManagement.update")
                : t("employeeManagement.submit")}
            </button>
          </form>
        </div>
      )}

      <table border="1" style={{ width: "100%", marginTop: "20px" }}>
        <thead>
          <tr>
            <th>{t("employeeManagement.name")}</th>
            <th>{t("employeeManagement.username")}</th>
            <th>{t("employeeManagement.password")}</th>
            <th>{t("employeeManagement.phone")}</th>
            <th>{t("employeeManagement.role")}</th>
            <th>{t("employeeManagement.action")}</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee, index) => (
            <tr key={index}>
              <td>{employee.name}</td>
              <td>{employee.username}</td>
              <td>
                <div>
                  {passwordVisibility[index] ? employee.password : "••••••••"}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => togglePasswordVisibility(index)}
                >
                  {passwordVisibility[index] ? <FaEye /> : <FaEyeSlash />}
                </div>
              </td>
              <td>{employee.phoneNumber}</td>
              <td>{employee.role}</td>
              <td>
                <span onClick={() => handleEdit(employee)}>
                  <MdEdit />
                </span>
                <span onClick={() => handleDelete(employee.user_id)}>
                  <MdDelete />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Employee;
