import React, { useState, useEffect } from "react";
import { MdEdit, MdDelete } from "react-icons/md";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { useTranslation } from "react-i18next";

const SubCategories = () => {
  const [showForm, setShowForm] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [categoryName, setCategroyName] = useState("");
  const [editingSubCategory, setEditingSubCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const rowsPerPage = 7;

  useEffect(() => {
    getSubCategories();
    getCategories();
  }, []);

  const getSubCategories = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetSubCategories);
      setSubCategories(response);
    } catch (error) {
      console.error("Error fetching subcategories:", error.message);
    }
  };

  const getCategories = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetCategories);
      setCategories(response);
    } catch (error) {
      console.error("Error getting categories", error.message);
    }
  };

  const handleAddSubCategory = () => {
    setShowForm(true);
    setSubCategoryName("");
    setCategroyName("");
    setEditingSubCategory(null);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditingSubCategory(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        subCategoryName: subCategoryName,
        categoryName: categoryName,
      };

      if (editingSubCategory) {
        await apiService.putCall(
          `${apiPath.apiUpdateSubCategory}/${editingSubCategory.subCategory_id}`,
          payload
        );
      } else {
        await apiService.postCall(apiPath.apiCreateSubCategory, payload);
      }

      getSubCategories();
      setShowForm(false);
      setEditingSubCategory(null);
      setCategroyName("");
      console.log("dafgds", payload);
    } catch (error) {
      console.error("Error saving subcategory:", error.message);
    }
  };

  const handleEdit = (item) => {
    setShowForm(true);
    setEditingSubCategory(item);
    setSubCategoryName(item.subCategoryName);
    setCategroyName(item.categoryName);
  };

  const handleDelete = async (subCategory_id) => {
    try {
      await apiService.deleteCall(
        `${apiPath.apiDeleteSubCategory}/${subCategory_id}`
      );
      getSubCategories();
    } catch (error) {
      console.error("Error deleting subcategory:", error.message);
    }
  };

  const paginatedSubCategories = subCategories.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(subCategories.length / rowsPerPage);

  const toggleStatus = async (subCategory) => {
    try {
      const updatedSubCategory = {
        ...subCategory,
        isActive: !subCategory.isActive,
      };

      await apiService.putCall(
        `${apiPath.apiUpdateSubCategory}/${subCategory.subCategory_id}`,
        { isActive: updatedSubCategory.isActive }
      );

      getSubCategories();
    } catch (error) {
      console.error("Error updating subcategory status:", error.message);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>{t("subCategories.title")}</h1>
      {!showForm && (
        <button
          onClick={handleAddSubCategory}
          style={{
            padding: "10px 20px",
            marginBottom: "20px",
            cursor: "pointer",
          }}
        >
          {t("subCategories.addSubCategory")}
        </button>
      )}

      {showForm && (
        <div
          style={{
            position: "relative",
            border: "1px solid #ddd",
            padding: "20px",
            marginBottom: "20px",
            backgroundColor: "#f9f9f9",
            borderRadius: "5px",
          }}
        >
          <button
            onClick={handleCloseForm}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "transparent",
              border: "none",
              fontSize: "20px",
              cursor: "pointer",
              color: "#333",
            }}
          >
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <div>
              <label>{t("subCategories.category")}</label>
              <select
                value={categoryName}
                className="input"
                onChange={(e) => setCategroyName(e.target.value)}
                required
                style={{ marginLeft: "10px" }}
              >
                <option value="" disabled>
                  {t("subCategories.selectCategory")}
                </option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.categoryName}
                  </option>
                ))}
              </select>

              <label>
                {t("subCategories.subCategoryName")}:
                <input
                  type="text"
                  className="input"
                  value={subCategoryName}
                  onChange={(e) => setSubCategoryName(e.target.value)}
                  style={{ marginLeft: "10px" }}
                  required
                />
              </label>
            </div>
            <button
              type="submit"
              style={{
                marginTop: "10px",
                padding: "10px 20px",
                cursor: "pointer",
              }}
            >
              {editingSubCategory
                ? t("subCategories.update")
                : t("subCategories.submit")}
            </button>
          </form>
        </div>
      )}
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("subCategories.serialNumber")}
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("subCategories.subCategoryName")}
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("subCategories.status")}
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("subCategories.action")}
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedSubCategories.length > 0 ? (
            paginatedSubCategories.map((item, index) => (
              <tr key={item.id}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {(currentPage - 1) * rowsPerPage + index + 1}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {item.subCategoryName}
                </td>
                <td
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={item.isActive}
                    onChange={() => toggleStatus(item)}
                  />
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MdEdit
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={() => handleEdit(item)}
                  />
                  <MdDelete
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(item.subCategory_id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="4"
                style={{ border: "1px solid #ddd", padding: "8px" }}
              >
                {t("subCategories.noData")}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {paginatedSubCategories.length > rowsPerPage && (
        <div>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              style={{
                padding: "5px 10px",
                margin: "0 5px",
                cursor: "pointer",
                backgroundColor: currentPage === page ? "#007bff" : "#f4f4f4",
                color: currentPage === page ? "white" : "black",
              }}
            >
              {page}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubCategories;
