import React, { useState, useEffect } from "react";
import { MdEdit, MdDelete } from "react-icons/md";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { useTranslation } from "react-i18next";

const Areas = () => {
  const [showForm, setShowForm] = useState(false);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("");
  const [editingArea, setEditingArea] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const rowsPerPage = 7;

  useEffect(() => {
    fetchAreas();
  }, []);

  const fetchAreas = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAreas);
      setAreas(response);
    } catch (error) {
      console.error("Error fetching areas:", error.message);
    }
  };

  const handleAddArea = () => {
    setShowForm(true);
    setArea("");
    setEditingArea(null);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditingArea(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingArea) {
        await apiService.putCall(
          `${apiPath.apiUpdateArea}/${editingArea.area_id}`,
          { area }
        );
      } else {
        await apiService.postCall(apiPath.apiCreateArea, { area });
      }

      fetchAreas();
      setShowForm(false);
    } catch (error) {
      console.error("Error saving area:", error.message);
    }
  };

  const handleEdit = (item) => {
    setShowForm(true);
    setEditingArea(item);
    setArea(item.area);
  };

  const handleDelete = async (area_id) => {
    try {
      await apiService.deleteCall(`${apiPath.apiDeleteArea}/${area_id}`);
      fetchAreas();
    } catch (error) {
      console.error("Error deleting area:", error.message);
    }
  };

  const paginatedAreas = areas.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(areas.length / rowsPerPage);

  return (
    <div style={{ padding: "20px" }}>
      <h1>{t("areas.title")}</h1>
      {!showForm && (
        <button
          onClick={handleAddArea}
          style={{
            padding: "10px 20px",
            marginBottom: "20px",
            cursor: "pointer",
          }}
        >
          {t("areas.addButton")}
        </button>
      )}

      {showForm && (
        <div
          style={{
            position: "relative",
            border: "1px solid #ddd",
            padding: "20px",
            marginBottom: "20px",
            backgroundColor: "#f9f9f9",
            borderRadius: "5px",
          }}
        >
          <button
            onClick={handleCloseForm}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "transparent",
              border: "none",
              fontSize: "20px",
              cursor: "pointer",
              color: "#333",
            }}
          >
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <div>
              <label>
                {t("areas.form.label")}:
                <input
                  type="text"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                  style={{ marginLeft: "10px" }}
                  required
                />
              </label>
            </div>
            <button
              type="submit"
              style={{
                marginTop: "10px",
                padding: "10px 20px",
                cursor: "pointer",
              }}
            >
              {editingArea ? t("areas.form.updateButton") : t("areas.form.submitButton")}
            </button>
          </form>
        </div>
      )}

      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("areas.table.serial")}
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("areas.table.name")}
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("areas.table.actions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedAreas.length > 0 ? (
            paginatedAreas.map((item, index) => (
              <tr key={item.area_id}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {(currentPage - 1) * rowsPerPage + index + 1}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {item.area}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MdEdit
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={() => handleEdit(item)}
                  />
                  <MdDelete
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(item.area_id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="3"
                style={{ border: "1px solid #ddd", padding: "8px" }}
              >
                {t("areas.table.noData")}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {areas.length > rowsPerPage && (
        <div>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              style={{
                padding: "5px 10px",
                margin: "0 5px",
                cursor: "pointer",
                backgroundColor: currentPage === page ? "#007bff" : "#f4f4f4",
                color: currentPage === page ? "white" : "black",
              }}
            >
              {page}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Areas;
