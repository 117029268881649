import React, { useEffect, useState } from "react";
import "../assets/css/Expenditure.css";
import { MdEdit, MdDelete } from "react-icons/md";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { useTranslation } from "react-i18next";

const Sales = () => {
  const [showForm, setShowForm] = useState(false);
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [sale, setSale] = useState("");
  const [amount, setAmount] = useState("");
  const [editingSales, setEditingSales] = useState(null);
  const [sales, setSales] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getSales();
  }, []);

  const getSales = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetSales);
      if (response && response.data && response.data.length > 0) {
        setSales(response.data);
      }
    } catch (error) {
      console.error("Error getting expenditure", error.message);
    }
  };

  const handleToggleForm = () => {
    setShowForm((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "date") setDate(value);
    if (name === "sale") setSale(value);
    if (name === "amount") setAmount(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      date,
      sales: sale,
      amount,
    };

    try {
      if (editingSales) {
        await apiService.putCall(
          `${apiPath.apiUpdateSales}/${editingSales._id}`,
          payload
        );
      } else {
        await apiService.postCall(apiPath.apiCreateSales, payload);
      }

      setShowForm(false);
      setEditingSales(null);
      setDate(new Date().toISOString().split("T")[0]);
      setSale("");
      setAmount("");
      getSales();
    } catch (error) {
      console.error("Error saving expenditure", error.message);
    }
  };

  const handleEdit = (item) => {
    setEditingSales(item);
    setDate(item.date);
    setSale(item.sales);
    setAmount(item.amount);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    try {
      await apiService.deleteCall(`${apiPath.apiDeleteSales}/${id}`);
      getSales();
    } catch (error) {
      console.error("Error deleting expenditure", error.message);
    }
  };

  return (
    <div className="container">
      <button className="open-form-btn" onClick={handleToggleForm}>
        {t("sales.button")}
      </button>

      {showForm && (
        <div className="form-container">
          <button className="close-btn" onClick={handleToggleForm}>
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <label htmlFor="date">{t("sales.date")}:</label>
            <input
              type="date"
              id="date"
              name="date"
              value={date}
              onChange={handleChange}
              required
            />

            <label htmlFor="sale">{t("sales.name")}:</label>
            <input
              type="text"
              id="sale"
              name="sale"
              value={sale}
              onChange={handleChange}
              placeholder={t("sales.placeholderName")}
              required
            />

            <label htmlFor="amount">{t("sales.amount")}:</label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={amount}
              onChange={handleChange}
              placeholder={t("sales.placeholderAmount")}
              required
            />

            <button type="submit">
              {editingSales ? t("sales.update") : t("sales.submit")}
            </button>
          </form>
        </div>
      )}

      <table className="sales-table">
        <thead>
          <tr>
            <th>{t("sales.tableHeaders.date")}</th>
            <th>{t("sales.tableHeaders.name")}</th>
            <th>{t("sales.tableHeaders.amount")}</th>
            <th>{t("sales.tableHeaders.action")}</th>
          </tr>
        </thead>
        <tbody>
          {sales.map((item, index) => (
            <tr key={item._id}>
              <td>{item.date}</td>
              <td>{item.sales}</td>
              <td>{item.amount}</td>
              <td>
                <span
                  className="action-btn edit-btn"
                  onClick={() => handleEdit(item)}
                >
                  <MdEdit />
                </span>
                <span
                  className="action-btn delete-btn"
                  onClick={() => handleDelete(item._id)}
                >
                  <MdDelete />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Sales;
