import React, { useState, useEffect } from "react";
import { MdEdit, MdDelete } from "react-icons/md";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { useTranslation } from "react-i18next";

const Quantity = () => {
  const [showForm, setShowForm] = useState(false);
  const [quantities, setQuantities] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [quantityUnit, setQuantityUnit] = useState("litre");
  const [editingQuantity, setEditingQuantity] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const rowsPerPage = 7;

  useEffect(() => {
    getQuantities();
  }, []);

  const getQuantities = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetQuantity);
      setQuantities(response);
    } catch (error) {
      console.error("Error fetching quantities:", error.message);
    }
  };

  const handleAddQuantity = () => {
    setShowForm(true);
    setQuantity("");
    setQuantityUnit("litre");
    setEditingQuantity(null);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditingQuantity(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingQuantity) {
        await apiService.putCall(
          `${apiPath.apiUpdateQuantity}/${editingQuantity.quantity_id}`,
          { quantity, quantityUnit }
        );
      } else {
        await apiService.postCall(apiPath.apiCreateQuantity, {
          quantity,
          quantityUnit,
        });
      }

      getQuantities();
      setShowForm(false);
    } catch (error) {
      console.error("Error saving quantity:", error.message);
    }
  };

  const handleEdit = (item) => {
    setShowForm(true);
    setEditingQuantity(item);
    setQuantity(item.quantity);
    setQuantityUnit(item.quantityUnit);
  };

  const handleDelete = async (quantity_id) => {
    try {
      await apiService.deleteCall(
        `${apiPath.apiDeleteQuantity}/${quantity_id}`
      );
      getQuantities();
    } catch (error) {
      console.error("Error deleting quantity:", error.message);
    }
  };

  const paginatedQuantities = quantities.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(quantities.length / rowsPerPage);

  const toggleQuantityStatus = async (quant) => {
    try {
      const updatedQuantity = {
        ...quant,
        isActive: !quant.isActive,
      };

      await apiService.putCall(
        `${apiPath.apiUpdateQuantity}/${quant.quantity_id}`,
        { isActive: updatedQuantity.isActive }
      );

      getQuantities();
    } catch (error) {
      console.error("Error updating quantity status:", error.message);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>{t("quantity.title")}</h1>
      {!showForm && (
        <button
          onClick={handleAddQuantity}
          style={{
            padding: "10px 20px",
            marginBottom: "20px",
            cursor: "pointer",
          }}
        >
          {t("quantity.addQuantity")}
        </button>
      )}

      {showForm && (
        <div
          style={{
            position: "relative",
            border: "1px solid #ddd",
            padding: "20px",
            marginBottom: "20px",
            backgroundColor: "#f9f9f9",
            borderRadius: "5px",
          }}
        >
          <button
            onClick={handleCloseForm}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "transparent",
              border: "none",
              fontSize: "20px",
              cursor: "pointer",
              color: "#333",
            }}
          >
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <div>
              <label>
                {t("quantity.quantity")}:
                <input
                  type="text"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  style={{ marginLeft: "10px" }}
                  required
                />
              </label>
            </div>
            <div style={{ marginTop: "10px" }}>
              <label style={{ marginRight: "10px", fontWeight: "bold" }}>
                {t("quantity.unit")}:
              </label>
              <label>
                <input
                  type="radio"
                  name="unit"
                  value="litre"
                  checked={quantityUnit === "litre"}
                  onChange={(e) => setQuantityUnit(e.target.value)}
                />
                {t("quantity.litre")}
              </label>
              <label style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  name="unit"
                  value="millilitre"
                  checked={quantityUnit === "millilitre"}
                  onChange={(e) => setQuantityUnit(e.target.value)}
                />
                {t("quantity.millilitre")}
              </label>
              <label style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  name="unit"
                  value="gram"
                  checked={quantityUnit === "gram"}
                  onChange={(e) => setQuantityUnit(e.target.value)}
                />
                {t("quantity.gram")}
              </label>
            </div>
            <button
              type="submit"
              style={{
                marginTop: "10px",
                padding: "10px 20px",
                cursor: "pointer",
              }}
            >
              {editingQuantity ? t("quantity.update") : t("quantity.submit")}
            </button>
          </form>
        </div>
      )}
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("quantity.serialNo")}
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("quantity.quantity")}
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("quantity.unit")}
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("quantity.status")}
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              {t("quantity.action")}
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedQuantities.length > 0 ? (
            paginatedQuantities.map((item, index) => (
              <tr key={item.id}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {(currentPage - 1) * rowsPerPage + index + 1}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {item.quantity}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {item.quantityUnit}
                </td>
                <td
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={item.isActive}
                    onChange={() => toggleQuantityStatus(item)}
                  />
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MdEdit
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={() => handleEdit(item)}
                  />
                  <MdDelete
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(item.quantity_id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="5"
                style={{ border: "1px solid #ddd", padding: "8px" }}
              >
                {t("quantity.noData")}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {paginatedQuantities.length > rowsPerPage && (
        <div>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              style={{
                padding: "5px 10px",
                margin: "0 5px",
                cursor: "pointer",
                backgroundColor: currentPage === page ? "#007bff" : "#f4f4f4",
                color: currentPage === page ? "white" : "black",
              }}
            >
              {page}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Quantity;
