import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const syncGuestCartWithBackend = async (userId) => {
    const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
    if (guestCart.length === 0) return;

    try {
      for (const item of guestCart) {
        const payload = {
          userId,
          productId: item.productId,
          quantity: item.quantity,
        };
        await apiService.postCall(apiPath.apiAddToCart, payload);
      }
      localStorage.removeItem("guestCart");
      alert("Your cart has been synced with your account!");
    } catch (error) {
      console.error("Error syncing cart:", error.message);
      alert("Failed to sync cart. Please try again.");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const payload = { username, password };
      const loginResponse = await apiService.postCall(
        apiPath.apiUserLogin,
        payload
      );

      if (loginResponse) {
        const userRole = loginResponse.user.role;

        if (!userRole) {
          alert("Unauthorized Login!");
          return;
        }

        localStorage.setItem("login", true);
        localStorage.setItem("role", userRole);
        localStorage.setItem("user_id", loginResponse.user.id);

        Cookies.set("jwt_token", loginResponse.token, {
          expires: 10,
          secure: true,
          sameSite: "None",
        });

        await syncGuestCartWithBackend(loginResponse.user.id);

        if (userRole === "admin") {
          onLogin();
          navigate("/dashboard");
        } else if (userRole === "employee") {
          onLogin();
          navigate("/accounts");
        } else if (userRole === "vendor") {
          onLogin();
          navigate("/vendor");
        } else if (userRole === "onlineUser") {
          onLogin();
          navigate("/");
        } else {
          alert("Invalid Role!");
        }
      } else {
        alert("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("An error occurred while logging in. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <h2>{t("loginForm.title")}</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder={t("loginForm.usernamePlaceholder")}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <div className="password-input-container">
          <input
            type={showPassword ? "text" : "password"}
            placeholder={t("loginForm.passwordPlaceholder")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span
            className="password-toggle-icon"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <button type="submit">{t("loginForm.loginButton")}</button>

        <div>
          <p>
            {t("loginForm.noAccountText")}{" "}
            <a href="/register">{t("loginForm.registerLink")}</a>{" "}
            {t("loginForm.registerHere")}
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
