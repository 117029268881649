import React, { useEffect, useState } from "react";
import apiPath from "../config/apipath";
import apiService from "../services/apiService";
import "../assets/css/Orders.css";
import { MdEdit, MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Orders = () => {
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(7);
  const [editingOrder, setEditingOrder] = useState(null);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [crateCount, setCrateCount] = useState(0);
  const [pocketNumbers, setPocketNumbers] = useState("");
  const [amount, setAmount] = useState("");
  const [totals, setTotals] = useState({
    totalAmount: 0,
  });
  const [totalCrates, setTotalsCrate] = useState({
    totalCrates: 0,
  });
  const [customers, setCustomers] = useState([]);
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [admin, setAdmin] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedVendor) {
      const filtered = products.filter(
        (product) => product.vendor._id === selectedVendor
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
    setSelectedProduct(null);
    setCrateCount();
    setPocketNumbers(0);
    setAmount(0);
  }, [selectedVendor, products]);

  useEffect(() => {
    if (selectedProduct && crateCount > 0) {
      const countPerCrate = parseInt(selectedProduct.countPerCrate, 10) || 0;
      setPocketNumbers(crateCount * countPerCrate);
    } else {
      setPocketNumbers(0);
    }
  }, [crateCount, selectedProduct]);

  useEffect(() => {
    if (selectedProduct && pocketNumbers > 0) {
      const sellingPrice = parseFloat(selectedProduct.sellingPrice) || 0;
      setAmount(pocketNumbers * sellingPrice);
    } else {
      setAmount(0);
    }
  }, [pocketNumbers, selectedProduct]);

  useEffect(() => {
    getVendors();
    getOrders();
    getProducts();
    getCustomers();
    getAdmin();
  }, []);

  const getProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetProducts);
      if (response && response.length > 0) {
        setProducts(response);
      }
    } catch (error) {
      console.error("Error in fetching products:", error.message);
    }
  };

  const getOrders = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetOrders);
      if (response && response.length > 0) {
        const sortedOrders = response.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setOrders(sortedOrders);
        // console.log("sortedOrders", sortedOrders);
      }
    } catch (error) {
      console.error("Error in fetching orders:", error.message);
    }
  };

  const getVendors = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && response.length > 0) {
        const vendorList = response.filter((user) => user.role === "vendor");
        setVendors(vendorList);
        // console.log(vendorList);
      } else {
        console.error("Invalid response structure", response);
      }
    } catch (error) {
      console.error("Error in fetching vendors:", error.message);
    }
  };

  const getCustomers = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && response.length > 0) {
        const customerList = response.filter(
          (user) => user.role === "customer"
        );
        setCustomers(customerList);
        // console.log(customerList);
      } else {
        console.error("Invalid response structure", response);
      }
    } catch (error) {
      console.error("Error in fetching customers:", error.message);
    }
  };

  const getAdmin = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && response.length > 0) {
        const adminList = response.filter((user) => user.role === "admin");
        if (adminList.length > 0) {
          setAdmin(adminList[0]);
          // console.log("Admin Data:", adminList[0]);
        }
      }
    } catch (error) {
      console.error("Error in fetching admin details:", error.message);
    }
  };

  const sendWhatsAppMessage = (phoneNumber, message) => {
    const formattedNumber = phoneNumber.replace(/\D/g, "");
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${formattedNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  // ! work on quantity, it's still not clear...
  const generateWhatsAppMessage = () => {
    let message = `Hello ${
      vendors.find((v) => v._id === selectedVendor)?.name || "Vendor"
    },\n`;
    message += `Here is your order summary for ${date}:\n\n`;

    selectedProducts.forEach((item, index) => {
      message += `${index + 1}. ${item.productName} (Crate: ${item.crate})\n`;
      message += `   Quantity: ${item.quantity}L\n`;
      message += `   Amount: ${item.amount}\n\n`;
    });

    message += `Summary:\n`;
    message += `Total Amount: ${totals.totalAmount}\n`;
    message += `Total Crates: ${totalCrates.totalCrates}\n\n`;

    message += `Thank you!`;

    return message;
  };

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const response = await apiService.putCall(
        `${apiPath.apiUpdateOrder}/${orderId}`,
        { isDelivered: newStatus }
      );

      if (response) {
        getOrders();
      }
    } catch (error) {
      console.error("Error updating order status:", error.message);
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setShowCustomerForm(false);
  };

  const totalAmount = selectedProducts.reduce(
    (total, prod) => total + prod.amount,
    0
  );
  const totalCrate = selectedProducts.reduce(
    (total, prod) => total + prod.crate,
    0
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const orderData = {
        user: selectedVendor,
        date: date,
        orderdetails: selectedProducts.map((product) => ({
          productName: product.productName,
          quantity: product.quantity,
          count: product.pieces,
          amount: product.amount,
          crate: product.crate,
        })),
        totalCrate: totalCrate,
        totalAmount: totalAmount,
      };

      if (editingOrder) {
        await apiService.putCall(
          `${apiPath.apiUpdateOrder}/${editingOrder.order_id}`,
          orderData
        );
      } else {
        await apiService.postCall(apiPath.apiCreateOrder, orderData);
      }

      setSelectedProducts([]);
      setSelectedVendor("");
      setEditingOrder(null);
      setShowForm(false);
      getOrders();

      if (sendMessage) {
        const selectedVendorDetails = vendors.find(
          (v) => v._id === selectedVendor
        );
        if (selectedVendorDetails) {
          const phoneNumber = selectedVendorDetails.phoneNumber;
          const message = generateWhatsAppMessage();
          sendWhatsAppMessage(phoneNumber, message);
        } else {
          console.error("Vendor details not found.");
        }
      }
    } catch (error) {
      console.error("Error submitting order:", error.message);
    }
  };

  const handleEdit = (order) => {
    setShowForm(true);
    setEditingOrder(order);
    setDate(order.date);
    setSelectedVendor(order.user.name || "Online User");
    setSelectedProducts(
      order.orderdetails.map((detail) => ({
        productName: detail.productName,
        quantity: detail.quantity,
        count: detail.count,
        amount: detail.amount,
        crate: detail.crate,
      }))
    );
  };

  const handleDelete = async (order_id) => {
    try {
      const response = await apiService.deleteCall(
        `${apiPath.apiDeleteOrder}/${order_id}`
      );
      if (response) {
        getOrders();
      }
    } catch (error) {
      console.error("Error deleting order:", error.message);
    }
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(orders.length / ordersPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const totalAmount = selectedProducts.reduce(
      (acc, prod) => acc + prod.amount,
      0
    );
    setTotals({ totalAmount });
  }, [selectedProducts]);

  useEffect(() => {
    const totalCrates = selectedProducts.reduce(
      (sum, product) => sum + (product.crate || 0),
      0
    );
    setTotalsCrate({ totalCrates });
  }, [selectedProducts]);

  const generateCustomerWhatsAppMessage = () => {
    let message = `Hello ${admin.name},\n`;
    message += `Here is my (${
      customers.find((c) => c._id === selectedCustomer)?.name || "Customer"
    }) order summary for ${date}:\n\n`;

    selectedProducts.forEach((item, index) => {
      message += `${index + 1}. ${item.productName}\n`;
      message += `   Quantity: ${item.pieceCount} pieces\n`;
      message += `   Amount: ₹${item.amount}\n\n`;
    });

    message += `Total Amount: ₹${totals.totalAmount}\n\nThank you!`;
    return message;
  };

  const handleSubmitCustomerOrder = async (e) => {
    e.preventDefault();
    try {
      const orderData = {
        user: selectedCustomer,
        date,
        orderdetails: selectedProducts.map((product) => ({
          productName: product.productName,
          count: product.pieces,
          amount: product.amount,
        })),
        totalAmount: totals.totalAmount,
      };

      if (editingOrder) {
        await apiService.putCall(
          `${apiPath.apiUpdateOrder}/${editingOrder.order_id}`,
          orderData
        );
      } else {
        await apiService.postCall(apiPath.apiCreateOrder, orderData);
      }

      if (sendMessage) {
        const message = generateCustomerWhatsAppMessage();
        if (admin && message) {
          sendWhatsAppMessage(admin.phoneNumber, message);
        }
      }

      setSelectedProducts([]);
      setSelectedCustomer("");
    } catch (error) {
      console.error("Error submitting customer order:", error.message);
    }
  };

  const handleSelectProduct = (product) => {
    if (!selectedProducts.find((p) => p._id === product._id)) {
      setSelectedProducts((prev) => [
        ...prev,
        {
          ...product,
          quantity: parseInt(product.quantity.split(" ")[0], 10),
          crate: 0,
          pieces: 0,
          amount: 0,
        },
      ]);
    }
  };

  const handleDeselectProduct = (product) => {
    setSelectedProducts((prev) => prev.filter((p) => p._id !== product._id));
  };

  const handleCrateCountChange = (productId, delta) => {
    setSelectedProducts((prev) =>
      prev.map((product) =>
        product._id === productId
          ? {
              ...product,
              crate: Math.max(0, (product.crate || 0) + delta),
              amount:
                ((product.crate || 0) + delta) *
                product.countPerCrate *
                product.sellingPrice,
              pieces: ((product.crate || 0) + delta) * product.countPerCrate,
            }
          : product
      )
    );
    calculateTotals();
  };

  const handleCrateCountInput = (productId, crateCount) => {
    setSelectedProducts((prev) =>
      prev.map((product) =>
        product._id === productId
          ? {
              ...product,
              crate: crateCount,
              amount: crateCount * product.countPerCrate * product.sellingPrice,
              pieces: (product.crate || 0) * product.countPerCrate,
            }
          : product
      )
    );
    calculateTotals();
  };

  const calculateTotals = () => {
    const totalCrates = selectedProducts.reduce(
      (sum, product) => sum + (product.crate || 0),
      0
    );
    const totalAmount = selectedProducts.reduce(
      (sum, product) => sum + (product.amount || 0),
      0
    );
    setTotals({ totalCrates, totalAmount });
  };

  const handlePieceCountChange = (productId, change) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product._id === productId
          ? {
              ...product,
              pieces: Math.max(0, product.pieces + change),
              amount:
                Math.max(0, product.pieces + change) * product.sellingPrice,
            }
          : product
      )
    );
  };

  const handlePieceCountInput = (productId, value) => {
    const parsedValue = Math.max(0, Number(value));
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product._id === productId
          ? {
              ...product,
              pieces: parsedValue,
              amount: parsedValue * product.sellingPrice,
            }
          : product
      )
    );
  };

  return (
    <div className="orders-container">
      <div>
        <h1>{t("ordersDashboard.title")}</h1>
        <div className="orders-cards-container">
          <div className="orders-cards-container">
            <div
              className="orders-card"
              onClick={() => {
                setShowForm(true);
                setShowCustomerForm(false);
              }}
            >
              <h3>{t("ordersDashboard.vendors")}</h3>
            </div>
            <div
              className="orders-card"
              onClick={() => {
                setShowCustomerForm(true);
                setShowForm(false);
              }}
            >
              <h3>{t("ordersDashboard.customers")}</h3>
            </div>
          </div>
          <div className="orders-card">
            <h3>{t("ordersDashboard.onlineUsers")}</h3>
          </div>
        </div>
      </div>
      <div>
        {showForm && (
          <div className="form-container">
            <div>
              <h3>{t("ordersDashboard.submitOrder")}</h3>
            </div>
            <div className="form-close-btn">
              <button onClick={handleCloseForm}>&times;</button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>{t("ordersDashboard.date")}</label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="vendorSelect">
                  {t("ordersDashboard.vendor")}
                </label>
                <select
                  id="vendorSelect"
                  value={selectedVendor}
                  onChange={(e) => setSelectedVendor(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    {t("ordersDashboard.selectVendor")}
                  </option>
                  {vendors.map((vendor) => (
                    <option key={vendor._id} value={vendor._id}>
                      {vendor.name}
                    </option>
                  ))}
                </select>
              </div>

              {selectedVendor && (
                <div className="product-grid">
                  {filteredProducts.map((product) => {
                    const selectedProduct = selectedProducts.find(
                      (p) => p._id === product._id
                    );
                    const crate = selectedProduct?.crate || 0;
                    const pieces = crate * product.countPerCrate;
                    const amount = pieces * product.sellingPrice;

                    return (
                      <div>
                        <div className="product-row" key={product._id}>
                          <input
                            type="checkbox"
                            checked={!!selectedProduct}
                            onChange={(e) => {
                              if (e.target.checked) {
                                handleSelectProduct(product);
                              } else {
                                handleDeselectProduct(product);
                              }
                            }}
                          />
                          <span>{product.productName}</span>
                          <button
                            type="button"
                            onClick={() =>
                              handleCrateCountChange(product._id, -1)
                            }
                            disabled={!selectedProduct || crate <= 0}
                          >
                            -
                          </button>{" "}
                          <div className="crate-count">
                            <input
                              type="number"
                              value={crate}
                              onChange={(e) =>
                                handleCrateCountInput(
                                  product._id,
                                  parseInt(e.target.value)
                                )
                              }
                              disabled={!selectedProduct}
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() =>
                              handleCrateCountChange(product._id, 1)
                            }
                            disabled={!selectedProduct}
                          >
                            +
                          </button>
                        </div>
                        <span>
                          {t("ordersDashboard.pieces")}: {pieces}
                        </span>
                        <span>₹{amount.toFixed(2)}</span>
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="form-group">
                <label>{t("ordersDashboard.totalCrates")}</label>
                <input
                  type="text"
                  value={totalCrates.totalCrates || 0}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>{t("ordersDashboard.totalAmount")}</label>
                <input type="text" value={totals.totalAmount || 0} readOnly />
              </div>

              <div className="whatsapp-check">
                <label>
                  <input
                    type="checkbox"
                    checked={sendMessage}
                    onChange={() => setSendMessage(!sendMessage)}
                  />
                  {t("ordersDashboard.sendMessage")}
                </label>
              </div>
              <button type="submit">{t("ordersDashboard.submit")}</button>
            </form>
          </div>
        )}

        <div>
          {showCustomerForm && (
            <div className="form-container">
              <div>
                <h3>{t("ordersDashboard.submitOrder")}</h3>
              </div>
              <div className="form-close-btn">
                <button onClick={handleCloseForm}>&times;</button>
              </div>
              <form onSubmit={handleSubmitCustomerOrder}>
                <div className="form-group">
                  <label>{t("ordersDashboard.date")}</label>
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>{t("ordersDashboard.customer")}</label>
                  <select
                    value={selectedCustomer}
                    onChange={(e) => setSelectedCustomer(e.target.value)}
                  >
                    <option value="" disabled>
                      {t("ordersDashboard.selectCustomer")}
                    </option>
                    {customers.map((customer) => (
                      <option key={customer._id} value={customer._id}>
                        {customer.name} ({customer.shopName})
                      </option>
                    ))}
                  </select>
                </div>
                {selectedCustomer && (
                  <div className="product-grid">
                    {products.map((product) => {
                      const selectedProduct = selectedProducts.find(
                        (p) => p._id === product._id
                      );
                      const pieces = selectedProduct?.pieces || 0;
                      const amount = pieces * product.sellingPrice;

                      return (
                        <div className="product-row" key={product._id}>
                          <input
                            type="checkbox"
                            checked={!!selectedProduct}
                            onChange={(e) => {
                              if (e.target.checked) {
                                handleSelectProduct(product);
                              } else {
                                handleDeselectProduct(product);
                              }
                            }}
                          />
                          <span>{product.productName}</span>
                          <div className="piece-count">
                            <button
                              type="button"
                              onClick={() =>
                                handlePieceCountChange(product._id, -1)
                              }
                              disabled={!selectedProduct || pieces <= 0}
                            >
                              {t("ordersDashboard.decrease")}
                            </button>
                            <input
                              type="number"
                              value={pieces}
                              onChange={(e) =>
                                handlePieceCountInput(
                                  product._id,
                                  e.target.value
                                )
                              }
                              disabled={!selectedProduct}
                            />
                            <button
                              type="button"
                              onClick={() =>
                                handlePieceCountChange(product._id, 1)
                              }
                              disabled={!selectedProduct}
                            >
                              {t("ordersDashboard.increase")}
                            </button>
                          </div>
                          <span>₹{amount.toFixed(2)}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="form-group">
                  <label>{t("ordersDashboard.totalAmount")}</label>
                  <input
                    type="text"
                    value={selectedProducts.reduce(
                      (total, product) =>
                        total + product.pieces * product.sellingPrice,
                      0
                    )}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={sendMessage}
                      onChange={() => setSendMessage(!sendMessage)}
                    />
                    {t("ordersDashboard.sendMessage")}
                  </label>
                </div>
                <button type="submit">{t("ordersDashboard.submit")}</button>
              </form>
            </div>
          )}
        </div>
      </div>
      <div>
        <h2>{t("ordersDashboard.orders")}</h2>
        {orders.length > 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  <th>{t("ordersDashboard.serialNo")}</th>
                  <th>{t("ordersDashboard.date")}</th>
                  <th>{t("ordersDashboard.users")}</th>
                  <th>{t("ordersDashboard.totalAmount")}</th>
                  <th>{t("ordersDashboard.status")}</th>
                  <th>{t("ordersDashboard.action")}</th>
                </tr>
              </thead>
              <tbody>
                {currentOrders.map((order, index) => (
                  <tr key={order._id}>
                    <td>{indexOfFirstOrder + index + 1}</td>
                    <td>{new Date(order.date).toLocaleDateString()}</td>
                    <td>{order.user?.name || "Online User"}</td>
                    <td>₹{order.totalAmount}</td>
                    <td>
                      <select
                        value={order.status}
                        onChange={(e) =>
                          handleStatusChange(order._id, e.target.value)
                        }
                      >
                        <option value="pending">
                          {t("ordersDashboard.pending")}
                        </option>
                        <option value="completed">
                          {t("ordersDashboard.completed")}
                        </option>
                      </select>
                    </td>
                    <td>
                      <span onClick={() => handleEdit(order._id)}>
                        <MdEdit />
                      </span>
                      <span onClick={() => handleDelete(order._id)}>
                        <MdDelete />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <span
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {t("ordersDashboard.previous")}
              </span>
              <span>
                {currentPage} {t("ordersDashboard.of")} {totalPages}
              </span>
              <span
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {t("ordersDashboard.next")}
              </span>
            </div>
          </>
        ) : (
          <p>{t("ordersDashboard.noOrders")}</p>
        )}
      </div>
    </div>
  );
};

export default Orders;
