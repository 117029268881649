import React, { useState, useEffect } from "react";
import "../assets/css/ImageUpload.css";

const ImageUpload = ({ setPhoto, existingPhoto, isViewingProfile }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isNewUpload, setIsNewUpload] = useState(false);

  useEffect(() => {
    if (isViewingProfile && existingPhoto && !isNewUpload) {
      setSelectedImage(existingPhoto);
    }
  }, [existingPhoto, isViewingProfile, isNewUpload]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file.size > 1e6) {
      window.alert("Upload a file smaller than 1 MB");
      return;
    }

    const supportedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!supportedTypes.includes(file.type)) {
      window.alert(
        "Unsupported file type. Please upload a JPEG, PNG, or GIF image."
      );
      return;
    }

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setPhoto(file);
      setIsNewUpload(true);
    }
  };

  const resetToExistingPhoto = () => {
    setIsNewUpload(false);
    if (existingPhoto) setSelectedImage(existingPhoto);
  };

  return (
    <div className="image-upload">
      <label htmlFor="file-input" className="upload-label">
        {selectedImage ? (
          <img src={selectedImage} alt="uploaded" className="uploaded-image" />
        ) : (
          <div className="upload-placeholder">
            <span>Upload</span>
          </div>
        )}
      </label>
      <input
        id="file-input"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="file-input"
      />
    </div>
  );
};

export default ImageUpload;
