import React, { useState } from "react";
import "../assets/css/NotificationModal.css";
import { useTranslation } from "react-i18next";

const NotificationModal = ({
  notifications,
  onClose,
  onMarkAsRead,
  onDeleteNotifications,
}) => {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const { t } = useTranslation();

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    if (!notification.isRead) {
      onMarkAsRead(notification.notification_id);
    }
  };

  const handleCheckboxChange = (notificationId, isChecked) => {
    setSelectedIds((prev) =>
      isChecked
        ? [...prev, notificationId]
        : prev.filter((id) => id !== notificationId)
    );
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setSelectedIds(notifications.map((n) => n.notification_id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleDeleteClick = () => {
    if (selectedIds.length > 0) {
      onDeleteNotifications(selectedIds);
      setSelectedIds([]);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          X
        </button>
        {!selectedNotification ? (
          <>
            <h3>{t("notificationModal.title")}</h3>
            {notifications.length === 0 ? (
              <p className="no-notifications-message">
                {t("notificationModal.noNotifications")}
              </p>
            ) : (
              <>
                <div className="notification-actions">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => handleSelectAll(e.target.checked)}
                      checked={
                        selectedIds.length === notifications.length &&
                        notifications.length > 0
                      }
                    />
                    {t("notificationModal.selectAll")}
                  </label>
                  {selectedIds.length > 0 && (
                    <button className="delete-btn" onClick={handleDeleteClick}>
                      {t("notificationModal.deleteButton")}
                    </button>
                  )}
                </div>
                <ul className="notification-list">
                  {notifications.map((notification) => (
                    <li
                      key={notification.notification_id}
                      className={`notification-item ${
                        notification.isRead ? "" : "unread"
                      }`}
                    >
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(
                          notification.notification_id
                        )}
                        onChange={(e) =>
                          handleCheckboxChange(
                            notification.notification_id,
                            e.target.checked
                          )
                        }
                      />
                      <p
                        onClick={() => handleNotificationClick(notification)}
                        className="notification-text"
                      >
                        {notification.deliveryDate} - {notification.name}
                      </p>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        ) : (
          <div className="notification-details">
            <h3>{t("notificationModal.detailsTitle")}</h3>
            <p>
              <strong>{t("notificationModal.name")}:</strong>{" "}
              {selectedNotification.name}
            </p>
            <p>
              <strong>{t("notificationModal.phone")}:</strong>{" "}
              {selectedNotification.phoneNumber}
            </p>
            <p>
              <strong>{t("notificationModal.address")}:</strong>{" "}
              {selectedNotification.address}
            </p>
            <p>
              <strong>{t("notificationModal.deliveryDate")}:</strong>{" "}
              {selectedNotification.deliveryDate}
            </p>
            <ul>
              {selectedNotification.orderDetails.map((order) => (
                <li key={order._id}>
                  {order.productName} * {order.count} = ₹{order.amount}
                </li>
              ))}
            </ul>
            <button onClick={() => setSelectedNotification(null)}>
              {t("notificationModal.backButton")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationModal;
