import React, { useState, useEffect } from "react";
import apiPath from "../config/apipath";
import apiService from "../services/apiService";
import { MdEdit, MdDelete } from "react-icons/md";
import ImageUpload from "../components/ImageUpload";
import { useTranslation } from "react-i18next";

const apiEndpoint = process.env.REACT_APP_apiEndpoint;

const AddProducts = () => {
  const [showForm, setShowForm] = useState(false);
  const [productName, setProductName] = useState("");
  const [image, setImage] = useState("");
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [quantity, setQuantity] = useState("");
  const [countPerCrate, setCountPerCrate] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [wholeSalePrice, setWholeSalePrice] = useState("");
  const [quantities, setQuantities] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [editingProduct, setEditingProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 7;
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedVendor) {
      const filtered = categories.filter(
        (category) => category.vendor._id === selectedVendor
      );
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories([]);
    }
  }, [selectedVendor, categories]);

  useEffect(() => {
    getVendors();
    getCategories();
    getSubCategories();
    getQuantities();
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetProducts);
      if (response && response.length > 0) {
        setProducts(response);
      }
    } catch (error) {
      console.error("Error in fetching products:", error.message);
    }
  };

  const getVendors = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && response.length > 0) {
        const vendorList = response.filter((user) => user.role === "vendor");
        setVendors(vendorList);
        console.log(vendorList);
      } else {
        console.error("Invalid response structure", response);
      }
    } catch (error) {
      console.error("Error in fetching vendors:", error.message);
    }
  };

  const getCategories = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetCategories);
      const activeCategories = response.filter((category) => category.isActive);
      setCategories(activeCategories);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };

  const getSubCategories = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetSubCategories);
      const activeSubCategories = response.filter(
        (subcategory) => subcategory.isActive
      );
      setSubCategories(activeSubCategories);
    } catch (error) {
      console.error("Error fetching subcategories:", error.message);
    }
  };

  const getQuantities = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetQuantity);
      const activeQuantities = response.filter((quantity) => quantity.isActive);
      setQuantities(activeQuantities);
    } catch (error) {
      console.error("Error fetching quantities:", error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = new FormData();

    payload.append("productName", productName);
    payload.append("productImage", image);
    payload.append("vendor", selectedVendor);
    payload.append("category", selectedCategory);
    payload.append("subCategory", selectedSubCategory);
    payload.append("quantity", quantity);
    payload.append("countPerCrate", countPerCrate);
    payload.append("costPrice", costPrice);
    payload.append("sellingPrice", sellingPrice);
    payload.append("wholeSalePrice", wholeSalePrice);

    try {
      if (editingProduct) {
        await apiService.putCall(
          `${apiPath.apiUpdateProduct}/${editingProduct.product_id}`,
          payload
        );
      } else {
        await apiService.postCall(apiPath.apiCreateProduct, payload);
      }

      getProducts();
      resetFormFields();
      setShowForm(false);
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  const resetFormFields = () => {
    setProductName("");
    setImage("");
    setSelectedVendor("");
    setSelectedCategory("");
    setSelectedSubCategory("");
    setQuantity("");
    setCountPerCrate("");
    setCostPrice("");
    setSellingPrice("");
    setWholeSalePrice("");
  };

  const handleEditProduct = (product) => {
    setShowForm(true);
    setEditingProduct(product);
    setProductName(product.productName);
    setSelectedVendor(product.vendor._id);
    setSelectedCategory(product.category);
    setSelectedSubCategory(product.subCategory);
    setQuantity(product.quantity);
    setCountPerCrate(product.countPerCrate);
    setCostPrice(product.costPrice);
    setSellingPrice(product.sellingPrice);
    setWholeSalePrice(product.wholeSalePrice);
    setImage(product.productImage);
  };

  const toggleProductStatus = async (product) => {
    try {
      const updatedProduct = {
        ...product,
        isActive: !product.isActive,
      };

      await apiService.putCall(
        `${apiPath.apiUpdateProduct}/${product.product_id}`,
        { isActive: updatedProduct.isActive }
      );

      getProducts();
    } catch (error) {
      console.error("Error updating product status:", error.message);
    }
  };

  const handleDeleteProduct = async (product_id) => {
    try {
      const response = await apiService.deleteCall(
        `${apiPath.apiDeleteProduct}/${product_id}`
      );
      if (response) {
        console.log("Product deleted successfully:", response);
        getProducts();
      }
    } catch (error) {
      console.error("Error deleting product:", error.message);
    }
  };

  return (
    <div className="add-products-container">
      <h1>{t("addProducts.title")}</h1>
      <button className="add-product-btn" onClick={() => setShowForm(true)}>
        {t("addProducts.addProductButton")}
      </button>

      {showForm && (
        <div className="form-container">
          <div className="form-header">
            <h3>{t("addProducts.formTitle")}</h3>
            <button
              className="form-close-btn"
              onClick={() => setShowForm(false)}
            >
              &times;
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>{t("addProducts.fields.productName")}</label>
              <input
                type="text"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>{t("addProducts.fields.productImage")}</label>
              <ImageUpload setPhoto={setImage} existingPhoto={image} />
            </div>
            <div className="form-group">
              <label>{t("addProducts.fields.vendor")}</label>
              <select
                value={selectedVendor}
                onChange={(e) => setSelectedVendor(e.target.value)}
                required
              >
                <option value="" disabled>
                  {t("addProducts.fields.selectVendor")}
                </option>
                {vendors.map((vendor) => (
                  <option key={vendor._id} value={vendor._id}>
                    {vendor.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>{t("addProducts.fields.category")}</label>
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                required
                disabled={!selectedVendor}
              >
                <option value="">
                  {t("addProducts.fields.selectCategory")}
                </option>
                {filteredCategories.length > 0 ? (
                  filteredCategories.map((category) => (
                    <option
                      key={category.category_id}
                      value={category.categoryName}
                    >
                      {category.categoryName}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    {selectedVendor
                      ? t("addProducts.fields.noCategories")
                      : t("addProducts.fields.selectVendorFirst")}
                  </option>
                )}
              </select>
            </div>
            <div className="form-group">
              <label>{t("addProducts.fields.subCategory")}</label>
              <select
                value={selectedSubCategory}
                onChange={(e) => setSelectedSubCategory(e.target.value)}
              >
                <option value="" disabled>
                  {t("addProducts.fields.selectSubCategory")}
                </option>
                {subCategories.map((subcategory) => (
                  <option
                    key={subcategory._id}
                    value={subcategory.subCategoryName}
                  >
                    {subcategory.subCategoryName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>{t("addProducts.fields.quantity")}</label>
              <select
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                required
              >
                <option value="" disabled>
                  {t("addProducts.fields.selectQuantity")}
                </option>
                {quantities.map((quantity) => (
                  <option
                    key={quantity._id}
                    value={`${quantity.quantity} ${quantity.quantityUnit}`}
                  >
                    {quantity.quantity} ({quantity.quantityUnit})
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>{t("addProducts.fields.countPerCrate")}</label>
              <input
                type="number"
                value={countPerCrate}
                onChange={(e) => setCountPerCrate(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>{t("addProducts.fields.costPrice")}</label>
              <input
                type="number"
                value={costPrice}
                onChange={(e) => setCostPrice(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>{t("addProducts.fields.wholeSalePrice")}</label>
              <input
                type="number"
                value={wholeSalePrice}
                onChange={(e) => setWholeSalePrice(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>{t("addProducts.fields.sellingPrice")}</label>
              <input
                type="number"
                value={sellingPrice}
                onChange={(e) => setSellingPrice(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-btn">
              {t("addProducts.submitButton")}
            </button>
          </form>
        </div>
      )}

      <table className="products-table">
        <thead>
          <tr>
            <th>{t("addProducts.tableHeaders.sNo")}</th>
            <th>{t("addProducts.tableHeaders.productImage")}</th>
            <th>{t("addProducts.tableHeaders.productName")}</th>
            <th>{t("addProducts.tableHeaders.vendor")}</th>
            <th>{t("addProducts.tableHeaders.category")}</th>
            <th>{t("addProducts.tableHeaders.subCategory")}</th>
            <th>{t("addProducts.tableHeaders.costPrice")}</th>
            <th>{t("addProducts.tableHeaders.sellingPrice")}</th>
            <th>{t("addProducts.tableHeaders.wholeSalePrice")}</th>
            <th>{t("addProducts.tableHeaders.status")}</th>
            <th>{t("addProducts.tableHeaders.actions")}</th>
          </tr>
        </thead>
        <tbody>
          {products.length > 0 ? (
            products
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((product, index) => (
                <tr key={product.product_id}>
                  <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                  <td>
                    <img
                      src={`${apiEndpoint}uploads/products/${product.productImage}`}
                      alt="Product"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </td>
                  <td>{product.productName}</td>
                  <td>{product.vendor.name}</td>
                  <td>{product.category}</td>
                  <td>{product.subCategory}</td>
                  <td>{product.costPrice}</td>
                  <td>{product.sellingPrice}</td>
                  <td>{product.wholeSalePrice}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={product.isActive}
                      onChange={() => toggleProductStatus(product)}
                    />
                  </td>
                  <td>
                    <MdEdit onClick={() => handleEditProduct(product)} />
                    <MdDelete
                      onClick={() => handleDeleteProduct(product.product_id)}
                    />
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan="9">{t("addProducts.noProductsAvailable")}</td>
            </tr>
          )}
        </tbody>
      </table>

      {products.length > rowsPerPage && (
        <div className="pagination">
          {Array.from(
            { length: Math.ceil(products.length / rowsPerPage) },
            (_, i) => i + 1
          ).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={currentPage === page ? "active" : ""}
            >
              {page}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddProducts;
