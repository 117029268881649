const apiPath = {
  // * Users
  apiCreateUser: "addUsers",
  apiUserLogin: "login",
  apiGetAllUsers: "users",
  apiGetUserById: "users",
  apiUpdateUser: "edit-users",
  apiDeleteUser: "delete-users",

  // * Quantity
  apiGetQuantity: "getQuantity",
  apiCreateQuantity: "createQuantity",
  apiUpdateQuantity: "updateQuantities",
  apiDeleteQuantity: "deleteQuantities",

  // * Category
  apiCreateCategory: "createCategory",
  apiGetCategories: "getCategory",
  apiUpdateCategory: "updateCategory",
  apiDeleteCategory: "deleteCategory",

  // * SubCategory
  apiCreateSubCategory: "createSubCategory",
  apiGetSubCategories: "getSubCategory",
  apiUpdateSubCategory: "updateSubCategory",
  apiDeleteSubCategory: "deleteSubCategory",

  // * Orders
  apiCreateOrder: "createOrders",
  apiGetOrders: "getOrders",
  apiUpdateOrder: "updateOrders",
  apiAssignEmployee: "orders/assign-employee",
  apiDeleteOrder: "deleteOrders",

  // * Products
  apiCreateProduct: "createProduct",
  apiGetProducts: "getProduct",
  apiUpdateProduct: "updateProduct",
  apiDeleteProduct: "deleteProduct",

  // * Task Management
  apiCreateTaskManagement: "createTask-management",
  apiGetTaskManagements: "getTask-management",
  apiUpdateTaskManagement: "updateTask-management",
  apiDeleteTaskManagement: "deleteTask-management",

  // * EmployeeAddedProduct
  apiDeleteEmployeeProduct: "deleteEmployeeAddedProducts",
  apiUpdateAvailableQuantity: "updateAvaialableQuantity",
  apiUpdateEmployeeProduct: "apiUpdateEmployeeProduct",
  apiGetEmployeeProducts: "getEmployeeAddedProducts",
  apiCreateEmployeeProduct: "createEmployeeAddedProducts",

  // * EmployeeDeliveredProducts
  apiCreateDelivery: "createDelivery",
  apiGetDeliveries: "getDeliveries",
  apiUpdateDelivery: "updateDeliveries",
  apiDeleteDelivery: "deleteDeliveries",

  // * Areas
  apiCreateArea: "createArea",
  apiGetAreas: "getAreas",
  apiUpdateArea: "updateAreas",
  apiDeleteArea: "deleteAreas",

  // * Collections
  apiDeleteCollection: "deleteCollections",
  apiUpdateCollection: "updateCollections",
  apiGetCollections: "getCollections",
  apiCreateCollection: "createCollection",

  // * Cart
  apiClearCart: "clearCart",
  apiRemoveFromCart: "removeCartProduct",
  apiGetCart: "getCart",
  apiAddToCart: "createCart",

  // * Notifications
  apiCreateNotification: "createNotification",
  apiGetNotifications: "getNotifications",
  apiUpdateNotification: "updateNotifications",
  apiDeleteNotification: "deleteNotifications",

  // * Expenditure
  apiCreateExpenditure: "createExpenditure",
  apiUpdateExpenditure: "updateExpenditure",
  apiGetExpenditure: "getExpenditure",
  apiDeleteExpenditure: "deleteExpenditure",

  // * OtherProductSales
  apiCreateSales: "createSales",
  apiGetSales: "getSales",
  apiUpdateSales: "updateSales",
  apiDeleteSales: "deleteSales",

  // *Proof Of Delivery
  apiCreateVendorDelivery: "createVendorDeliveries",
  apiGetAllVendorDeliveries: "getVendorDeliveries",
  apiUpdateVendorDeliveries: "updateVendorDeliveries",
  apiDeleteVendorDeliveries: "deleteVendorDeliveries",
};

export default apiPath;
