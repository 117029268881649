import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiPath from "../config/apipath";
import apiService from "../services/apiService";
import "../assets/css/Nav.css";
import logo from "../assets/images/logo.png";
import {
  FaUser,
  FaBars,
  FaTimes,
  FaShoppingCart,
  FaBell,
} from "react-icons/fa";
import NotificationModal from "../Pages/NotificationModal";
import { useTranslation } from "react-i18next";

const Nav = ({ toggleSidebar, isSidebarOpen, isCartVisible }) => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("login") === "true";
  const [role, setRole] = useState(null);
  const user_id = localStorage.getItem("user_id");
  const [cartProductsCount, setCartProductsCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [activeLanguage, setActiveLanguage] = useState("en");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      getCartProducts();
    } else {
      getGuestCartCount();
    }
    getNotifications();
  }, [isLoggedIn]);

  useEffect(() => {
    const handleNotificationsUpdate = () => {
      getNotifications();
    };

    window.addEventListener("notificationsUpdated", handleNotificationsUpdate);

    return () => {
      window.removeEventListener(
        "notificationsUpdated",
        handleNotificationsUpdate
      );
    };
  }, []);

  const getCartProducts = async () => {
    try {
      const response = await apiService.getCall(
        `${apiPath.apiGetCart}/${user_id}`
      );

      if (response) {
        const cartItems = response.cart || [];
        const cartCount = cartItems.length;
        setCartProductsCount(cartCount);
      } else {
        setCartProductsCount(0);
        console.warn("Cart is empty or response is invalid.");
      }
    } catch (error) {
      console.error("Error in getting Cart Products:", error.message);
      setCartProductsCount(0);
    }
  };

  const getGuestCartCount = () => {
    try {
      const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
      setCartCount(guestCart.length);
    } catch (error) {
      console.error("Error in fetching guest cart:", error.message);
      setCartCount(0);
    }
  };

  const getNotifications = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetNotifications);
      if (response && response.length > 0) {
        setNotifications(response);
        setUnreadCount(response.filter((n) => !n.isRead).length);
      }
    } catch (error) {
      console.error("Error in getting notifications", error.message);
    }
  };

  const handleDeleteNotifications = async (notificationIds) => {
    try {
      const response = await apiService.postCall(
        apiPath.apiDeleteNotification,
        {
          notification_ids: notificationIds,
        }
      );

      if (response) {
        setNotifications((prev) =>
          prev.filter((n) => !notificationIds.includes(n.notification_id))
        );
      }
    } catch (error) {
      console.error("Error deleting notifications:", error.message);
    }
  };

  useEffect(() => {
    const handleCartCountChange = (event) => {
      if (isLoggedIn) {
        setCartCount(event.detail);
      } else {
        const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
        setCartCount(guestCart.length);
      }
    };

    window.addEventListener("cartCountChanged", handleCartCountChange);

    return () => {
      window.removeEventListener("cartCountChanged", handleCartCountChange);
    };
  }, [isLoggedIn]);

  const handleCheckout = () => {
    navigate("/checkout");
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    const socket = new WebSocket("ws://localhost:8888/");

    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);
      setUnreadCount((prevCount) => prevCount + 1);
      setNotifications((prevNotifications) => [
        newNotification,
        ...prevNotifications,
      ]);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close();
    };
  }, []);

  const handleBellClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateNotificationStatus = async (notificationId) => {
    try {
      await apiService.putCall(
        `${apiPath.apiUpdateNotification}/${notificationId}`,
        {
          isRead: true,
        }
      );
      setNotifications((prev) =>
        prev.map((n) =>
          n.notification_id === notificationId ? { ...n, isRead: true } : n
        )
      );
      setUnreadCount((prev) => prev - 1);
    } catch (error) {
      console.error("Error updating notification status:", error.message);
    }
  };

  const shouldShowHamburger = isLoggedIn && role !== "onlineUser";
  const showBellIcon = isLoggedIn && role === "admin";

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setActiveLanguage(lang);
  };

  return (
    <nav className="navbar">
      {shouldShowHamburger && (
        <div className="hamburger-menu" onClick={toggleSidebar}>
          {isSidebarOpen ? <FaTimes /> : <FaBars />}
        </div>
      )}

      <div className="nav-left">
        <img src={logo} alt={t("logoAltText")} className="nav-logo" />
      </div>

      <div className="nav-right">
        <div className="language-switcher">
          <button
            className={activeLanguage === "en" ? "active" : ""}
            onClick={() => changeLanguage("en")}
          >
            E
          </button>
          <button
            className={activeLanguage === "ta" ? "active" : ""}
            onClick={() => changeLanguage("ta")}
          >
            த
          </button>
        </div>
        <div className="shopping-cart">
          {isCartVisible && (
            <div className="cart-icon nav-user-icon" onClick={handleCheckout}>
              <FaShoppingCart />
              {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
            </div>
          )}
        </div>
        <div>
          {showBellIcon && (
            <div className="bell-icon nav-user-icon" onClick={handleBellClick}>
              <FaBell />
              {unreadCount > 0 && (
                <span className="notification-count">{unreadCount}</span>
              )}
            </div>
          )}
        </div>
        <div className="user-menu">
          {isLoggedIn ? (
            <>
              <FaUser className="nav-user-icon" />
              <div className="dropdown-content">
                <a href="/login" onClick={handleLogout}>
                  {t("logout")}
                </a>
              </div>
            </>
          ) : (
            <button onClick={handleLogin}>{t("login")}</button>
          )}
        </div>
      </div>
      {isModalOpen && (
        <NotificationModal
          notifications={notifications}
          onClose={closeModal}
          onMarkAsRead={updateNotificationStatus}
          onDeleteNotifications={handleDeleteNotifications}
        />
      )}
    </nav>
  );
};

export default Nav;
