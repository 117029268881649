import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/Sidebar.css";
import { useTranslation } from "react-i18next";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const [isMastersExpanded, setIsMastersExpanded] = useState(false);
  const [role, setRole] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);
  }, []);

  const toggleMastersMenu = () => {
    setIsMastersExpanded((prev) => !prev);
  };

  const handleItemClick = (link) => {
    navigate(link);
    if (window.innerWidth <= 768) {
      toggleSidebar();
    }
  };

  if (role === "onlineUser") {
    return null;
  }

  return (
    <aside className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="close-button" onClick={toggleSidebar}>
        &times;
      </div>
      <ul>
        {role === "admin" && (
          <li>
            <div className="sidebar-buttons">
              <button onClick={() => handleItemClick("/dashboard")}>
                {t("sidebar.dashboard")}
              </button>
            </div>
          </li>
        )}

        {role === "admin" && (
          <li>
            <div className="sidebar-buttons">
              <button onClick={() => handleItemClick("/orders")}>
                {t("sidebar.orders")}
              </button>
            </div>
          </li>
        )}

        {role === "admin" && (
          <li>
            <div className="sidebar-buttons">
              <button onClick={() => handleItemClick("/products/add")}>
                {t("sidebar.products")}
              </button>
            </div>
          </li>
        )}

        {/* {role === "admin" && (
          <li>
            <div className="sidebar-buttons">
              <button onClick={() => handleItemClick("/task-assign/employee")}>
                {t("sidebar.taskManagement")}
              </button>
            </div>
          </li>
        )} */}

        {(role === "admin" || role === "employee") && (
          <li>
            <div className="sidebar-buttons">
              <button onClick={() => handleItemClick("/employee/task")}>
                {t("sidebar.task")}
              </button>
            </div>
          </li>
        )}

        {(role === "admin" || role === "employee") && (
          <li>
            <div className="sidebar-buttons">
              <button onClick={() => handleItemClick("/proofOfDeliveries")}>
                {t("sidebar.vendorDeliveries")}
              </button>
            </div>
          </li>
        )}

        {role === "employee" && (
          <li>
            <div className="sidebar-buttons">
              <button onClick={() => handleItemClick("/customers")}>
                {t("sidebar.customers")}
              </button>
            </div>
          </li>
        )}

        {(role === "admin" || role === "customer") && (
          <li>
            <div className="sidebar-buttons">
              <button onClick={() => handleItemClick("/userview")}>
                {t("sidebar.userView")}
              </button>
            </div>
          </li>
        )}

        {(role === "admin" || role === "employee") && (
          <li>
            <div className="sidebar-buttons">
              <button onClick={() => handleItemClick("/accounts")}>
                {t("sidebar.accounts")}
              </button>
            </div>
          </li>
        )}

        {role === "admin" && (
          <li>
            <div className="submenu">
              <div className="submenu-header" onClick={toggleMastersMenu}>
                <span>{t("sidebar.master")}</span>
                <span className="submenu-arrow">
                  {isMastersExpanded ? "▼" : "▶"}
                </span>
              </div>
              {isMastersExpanded && (
                <ul className="submenu-items">
                  <li>
                    <div className="sidebar-buttons">
                      <button
                        onClick={() => handleItemClick("/masters/categories")}
                      >
                        {t("sidebar.masters.categories")}
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="sidebar-buttons">
                      <button
                        onClick={() =>
                          handleItemClick("/masters/subcategories")
                        }
                      >
                        {t("sidebar.masters.subcategories")}
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="sidebar-buttons">
                      <button
                        onClick={() => handleItemClick("/masters/quantity")}
                      >
                        {t("sidebar.masters.quantity")}
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="sidebar-buttons">
                      <button onClick={() => handleItemClick("/areas")}>
                        {t("sidebar.masters.areas")}
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="sidebar-buttons">
                      <button onClick={() => handleItemClick("/employees")}>
                        {t("sidebar.masters.employees")}
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="sidebar-buttons">
                      <button onClick={() => handleItemClick("/vendor")}>
                        {t("sidebar.masters.vendors")}
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="sidebar-buttons">
                      <button onClick={() => handleItemClick("/customers")}>
                        {t("sidebar.masters.customers")}
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="sidebar-buttons">
                      <button
                        onClick={() => handleItemClick("/expenditures&sales")}
                      >
                        {t("sidebar.masters.expendituresAndSales")}
                      </button>
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </li>
        )}
      </ul>
    </aside>
  );
};

export default Sidebar;
