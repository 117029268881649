// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import en from "./en.json";
import ta from "./ta.json";

i18n
  .use(Backend) // You can use this to load translations from an external source, or use static files
  .use(LanguageDetector) // Automatically detect the user's language
  .use(initReactI18next) // Integrate i18next with React
  .init({
    fallbackLng: "en",
    resources: {
      en: { translation: en },
      ta: { translation: ta },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
