import React, { useEffect, useState } from "react";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import "../assets/css/TaskAssignment.css";
import { MdEdit, MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";

const TaskAssignment = () => {
  const [tasks, setTasks] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [dateFilter, setDateFilter] = useState("");
  const [areaFilter, setAreaFilter] = useState("");
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showEmployeeSelection, setShowEmployeeSelection] = useState(false);
  const [showExtraForm, setShowExtraForm] = useState(false);
  const [extraProducts, setExtraProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProductCount, setSelectedProductCount] = useState(0);
  const [showTaskAssign, setShowTaskAssign] = useState(true);
  const [showUpdateTask, setShowUpdateTask] = useState(false);
  const [expandedTaskId, setExpandedTaskId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDateFilter(today);
    getEmployees();
    getOrders();
    getProducts();
    getTasks();
  }, []);

  const getTasks = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetTaskManagements);
      if (response && response.length > 0) {
        setTasks(response);
      }
    } catch (error) {
      console.error("Error in getting Tasks", error.message);
    }
  };

  const getEmployees = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && response.length > 0) {
        const employeeList = response.filter((emp) => emp.role === "employee");
        setEmployees(employeeList);
      }
    } catch (error) {
      console.error("Error in fetching Employees", error.message);
    }
  };

  const getOrders = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetOrders);
      if (response && response.length > 0) {
        const unDeliveredOrders = response.filter(
          (order) => !order.isDelivered
        );
        setOrders(unDeliveredOrders);
      }
    } catch (error) {
      console.error("Error in fetching Orders", error.message);
    }
  };

  const getProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetProducts);
      if (response && response.length > 0) {
        setProducts(response);
      }
    } catch (error) {
      console.error("Error in fetching products:", error.message);
    }
  };

  const handleToggleTaskState = () => {
    setShowUpdateTask((prevState) => !prevState);
    if (!showUpdateTask) {
      setShowTaskAssign(false);
    } else {
      setShowTaskAssign(true);
    }
  };

  const applyFilters = () => {
    let filtered = [...orders];

    if (dateFilter) {
      filtered = filtered.filter((order) => order.date === dateFilter);
    }

    if (areaFilter) {
      filtered = filtered.filter((order) => order.user.area === areaFilter);
    }

    setFilteredOrders(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [dateFilter, areaFilter, orders]);

  const toggleOrderDetails = (index) => {
    const updatedOrders = [...filteredOrders];
    updatedOrders[index].showDetails = !updatedOrders[index].showDetails;
    setFilteredOrders(updatedOrders);
  };

  const toggleOrderSelection = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const handleEmployeeSelection = (employeeId) => {
    setSelectedEmployee((prevSelected) =>
      prevSelected === employeeId ? null : employeeId
    );
  };

  const closeEmployeePopup = () => {
    setShowEmployeeSelection(false);
    setSelectedEmployee(null);
  };

  const handleDelete = async (task_id) => {
    try {
      const response = await apiService.deleteCall(
        `${apiPath.apiDeleteTaskManagement}/${task_id}`
      );
      if (response) {
        console.log("Order deleted successfully:", response);
        getOrders();
      }
    } catch (error) {
      console.error("Error deleting task:", error.message);
    }
  };

  const renderExtraForm = () => {
    const selectedProduct = products.find(
      (product) => product._id === selectedProductId
    );

    const handleAddProduct = () => {
      if (!selectedProduct || selectedProductCount <= 0) return;

      const newExtraProduct = {
        productName: selectedProduct.productName,
        count: selectedProductCount,
        amount: selectedProduct.sellingPrice * selectedProductCount,
      };

      setExtraProducts((prev) => [...prev, newExtraProduct]);
      setSelectedProductId("");
      setSelectedProductCount(0);
    };

    const handleDone = () => {
      console.log("extra", extraProducts);
      setShowExtraForm(false);
    };

    return (
      <div className="extra-product-form">
        <h3>Add Extra Products</h3>
        <button className="close-btn" onClick={() => setShowExtraForm(false)}>
          &times;
        </button>

        <div className="form-group">
          <label>Product</label>
          <select
            value={selectedProductId}
            onChange={(e) => setSelectedProductId(e.target.value)}
          >
            <option value="">Select a Product</option>
            {products.map((product) => (
              <option key={product._id} value={product._id}>
                {product.productName}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Count</label>
          <input
            type="number"
            value={selectedProductCount}
            onChange={(e) => setSelectedProductCount(Number(e.target.value))}
          />
        </div>
        <button className="add-btn" onClick={handleAddProduct}>
          Add
        </button>

        {extraProducts.length > 0 && (
          <div className="extra-products-list">
            <h4>Added Products</h4>
            <ul>
              {extraProducts.map((product, index) => (
                <li key={index}>
                  {product.productName} - Count: {product.count}, Amount: ₹
                  {product.amount}
                </li>
              ))}
            </ul>
          </div>
        )}

        <button className="done-btn" onClick={handleDone}>
          Done
        </button>
      </div>
    );
  };

  const toggleDetails = (taskId) => {
    setExpandedTaskId(expandedTaskId === taskId ? null : taskId);
  };

  const calculateTotalAmount = (orders) => {
    return orders.reduce((sum, order) => sum + Number(order.totalAmount), 0);
  };

  const calculateExtraProductTotal = (extraProducts) => {
    return extraProducts.reduce(
      (sum, product) => sum + product.count * product.amount,
      0
    );
  };

  const handleAssign = async () => {
    const payload = {
      date: dateFilter,
      employee: selectedEmployee,
      orders: selectedOrders,
      extraProducts: extraProducts,
    };

    try {
      await apiService.postCall(apiPath.apiCreateTaskManagement, payload);

      const assignEmployeePayload = {
        assignedEmployee: selectedEmployee,
        order_ids: selectedOrders,
      };

      await apiService.putCall(
        apiPath.apiAssignEmployee,
        assignEmployeePayload
      );

      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          selectedOrders.includes(order._id)
            ? {
                ...order,
                assignedEmployee: selectedEmployee,
                assignedEmployeeName: employees.find(
                  (emp) => emp._id === selectedEmployee
                )?.name,
              }
            : order
        )
      );

      setExtraProducts([]);
      setSelectedOrders([]);
      setShowEmployeeSelection(false);
      setSelectedEmployee(null);
    } catch (error) {
      console.error("Error in assigning orders:", error.message);
    }
  };

  const renderEmployeeSelectionPopup = () => {
    return (
      <>
        <div className="popup-overlay" onClick={closeEmployeePopup}></div>
        <div className="employee-popup">
          <div className="popup-header">
            <h3>{t("employeePopup.title")}</h3>
            <button className="close-btn" onClick={closeEmployeePopup}>
              &times;
            </button>
          </div>
          <div className="popup-body">
            <div className="employee-list">
              {employees.map((employee) => (
                <label
                  key={employee._id}
                  className={`employee-option ${
                    selectedEmployee && selectedEmployee !== employee._id
                      ? "disabled"
                      : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={selectedEmployee === employee._id}
                    disabled={
                      selectedEmployee && selectedEmployee !== employee._id
                    }
                    onChange={() => handleEmployeeSelection(employee._id)}
                  />
                  {employee.name}
                </label>
              ))}
            </div>
          </div>
          <div className="popup-footer">
            {selectedEmployee && (
              <>
                <button
                  className="extra-btn"
                  onClick={() => setShowExtraForm(true)}
                >
                  {t("employeePopup.extraButton")}
                </button>
                <button className="assign-btn" onClick={handleAssign}>
                  {t("employeePopup.assignButton")}
                </button>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="task-assignment">
      <h1>{t("taskAssignment.title")}</h1>
      <div className="filter-buttons">
        <button onClick={handleToggleTaskState}>
          {showUpdateTask
            ? t("taskAssignment.assignTask")
            : t("taskAssignment.updateTask")}
        </button>
      </div>
      <div>
        {showTaskAssign && (
          <div>
            <div className="filters">
              <label>
                {t("taskAssignment.date")}:
                <input
                  type="date"
                  value={dateFilter}
                  onChange={(e) => setDateFilter(e.target.value)}
                />
              </label>
              <label>
                {t("taskAssignment.area")}:
                <select
                  value={areaFilter}
                  onChange={(e) => setAreaFilter(e.target.value)}
                >
                  <option value="">{t("taskAssignment.all")}</option>
                  {Array.from(
                    new Set(orders.map((order) => order.user?.area?.area))
                  ).map((area) => (
                    <option key={area} value={area}>
                      {area}
                    </option>
                  ))}
                </select>
              </label>
              <div className="filter-buttons">
                <button onClick={() => setShowCheckboxes((prev) => !prev)}>
                  {showCheckboxes
                    ? t("taskAssignment.cancelSelection")
                    : t("taskAssignment.selectOrders")}
                </button>
                {selectedOrders.length > 0 && (
                  <button onClick={() => setShowEmployeeSelection(true)}>
                    {t("taskAssignment.selectEmployee")}
                  </button>
                )}
              </div>
            </div>

            <div className="orders-grid">
              {filteredOrders.length > 0 ? (
                filteredOrders.map((order, index) => (
                  <div
                    key={order._id}
                    className={`order-card ${
                      order.assignedEmployee ? "assigned" : "unassigned"
                    }`}
                  >
                    <div className="order-summary">
                      {showCheckboxes && (
                        <input
                          type="checkbox"
                          checked={selectedOrders.includes(order._id)}
                          onChange={() => toggleOrderSelection(order._id)}
                        />
                      )}
                      <div>
                        <strong>{t("taskAssignment.shopName")}:</strong>{" "}
                        {order.user.shopName || t("taskAssignment.na")}
                      </div>
                      <div>
                        <strong>{t("taskAssignment.totalAmount")}:</strong> ₹
                        {order.totalAmount}
                      </div>
                      <div>
                        <strong>{t("taskAssignment.totalCount")}:</strong>{" "}
                        {order.orderdetails.reduce(
                          (sum, item) => sum + item.count,
                          0
                        )}
                      </div>
                      <button onClick={() => toggleOrderDetails(index)}>
                        {order.showDetails
                          ? t("taskAssignment.hideDetails")
                          : t("taskAssignment.viewDetails")}
                      </button>
                      {order.showDetails && (
                        <div className="order-details">
                          <h4>{t("taskAssignment.orderDetails")}</h4>
                          <ul>
                            {order.orderdetails.map((item, i) => (
                              <li key={i}>
                                {item.productName} x {item.count} = ₹
                                {item.amount}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <div>
                        <strong>{t("taskAssignment.assignedTo")}:</strong>{" "}
                        {order.assignedEmployee?.name ||
                          t("taskAssignment.notAssigned")}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-data">{t("taskAssignment.noDataFound")}</div>
              )}
            </div>

            {showEmployeeSelection && renderEmployeeSelectionPopup()}
            {showExtraForm && renderExtraForm()}
          </div>
        )}
      </div>
      <div>
        {showUpdateTask && (
          <div>
            <div className="task-grid">
              {tasks.map((task) => (
                <div key={task._id} className="task-card">
                  <h3>
                    {t("taskAssignment.assignedTo")}: {task.employee.name}
                  </h3>
                  <p>
                    {t("taskAssignment.totalAmount")}: ₹
                    {calculateTotalAmount(task.orders)}
                  </p>
                  <div className="filter-buttons">
                    <button onClick={() => toggleDetails(task._id)}>
                      {expandedTaskId === task._id
                        ? t("taskAssignment.hideDetails")
                        : t("taskAssignment.viewDetails")}
                    </button>
                  </div>
                  {expandedTaskId === task._id && (
                    <div className="task-details">
                      <h4>{t("taskAssignment.orderDetails")}</h4>
                      {task.orders.map((order) => (
                        <div key={order._id} className="order-details">
                          <p>
                            <strong>{t("taskAssignment.shopName")}:</strong>{" "}
                            {order.user.shopName}
                          </p>
                          {order.orderdetails.map((product, index) => (
                            <p key={index}>
                              {product.productName} x {product.count} = ₹
                              {product.amount}
                            </p>
                          ))}
                          <p>
                            <strong>{t("taskAssignment.totalAmount")}:</strong>{" "}
                            ₹{order.totalAmount}
                          </p>
                        </div>
                      ))}
                      {task.extraProducts.length > 0 && (
                        <div className="extra-products">
                          <h4>{t("taskAssignment.extraProducts")}</h4>
                          {task.extraProducts.map((product, index) => (
                            <p key={index}>
                              {product.productName} x {product.count} = ₹
                              {product.count * product.amount}
                            </p>
                          ))}
                          <p>
                            <strong>
                              {t("taskAssignment.extraProductsTotal")}:
                            </strong>{" "}
                            ₹{calculateExtraProductTotal(task.extraProducts)}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="task-actions">
                    <button>
                      <MdEdit />
                    </button>
                    <button>
                      <MdDelete
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(task.task_id)}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskAssignment;
