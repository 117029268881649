import React, { useState } from "react";
import Expenditure from "./Expenditure";
import Sales from "./OtherProductSales";
import { useTranslation } from "react-i18next";

const Expenditure_Sales = () => {
  const [activeComponent, setActiveComponent] = useState(null);
  const { t } = useTranslation();

  const handleExpenditureClick = () => {
    setActiveComponent("Expenditure");
  };

  const handleSalesClick = () => {
    setActiveComponent("Sales");
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "Expenditure":
        return <Expenditure />;
      case "Sales":
        return <Sales />;
      default:
        return true;
    }
  };

  return (
    <div className="employee-task-container">
      <div className="grid-container">
        <div className="grid-item">
          <button onClick={handleExpenditureClick}>
            {t("employeeTaskContainer.buttons.expenditures")}
          </button>
        </div>
        <div className="grid-item">
          <button onClick={handleSalesClick}>
            {t("employeeTaskContainer.buttons.sales")}
          </button>
        </div>
      </div>
      <div className="content-container">{renderComponent()}</div>
    </div>
  );
};

export default Expenditure_Sales;
