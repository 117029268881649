import React, { useState, useEffect } from "react";
import apiPath from "../config/apipath";
import apiService from "../services/apiService";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FaArrowCircleDown } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "../assets/css/Deliveries.css";

const Deliveries = () => {
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [selectedShop, setSelectedShop] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [customers, setCustomers] = useState([]);
  const [employeeAddedProducts, setEmployeeAddedProducts] = useState([]);
  const [empAddProducts, setEmpAddProducts] = useState([]);
  const [paymentOption, setPaymentOption] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [employeeId, setEmployeeId] = useState("");
  const [balance, setBalance] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [employeeName, setEmployeeName] = useState("");
  const [products, setProducts] = useState([]);
  const [employeeProduct, setEmployeeProduct] = useState([]);
  const [deliveredProducts, setDeliveredProducts] = useState([]);
  const [expandedShops, setExpandedShops] = useState({});
  const { t } = useTranslation();
  const uniqueAreas = [
    ...new Set(customers.map((customer) => customer.area?.area || "NA")),
  ];
  const [deliveredPdf, setDeliveredPdf] = useState([]);
  const [filterDate, setFilterDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [filterArea, setFilterArea] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredShops = customers.filter((customer) =>
    selectedArea === "NA"
      ? !customer.area
      : customer.area?.area === selectedArea
  );

  const filteredShopList = filteredShops.filter((shop) =>
    shop.shopName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const deliveryData = {
        date: date,
        shop: filteredShops.find((shop) => shop.shopName === selectedShop)._id,
        deliveredProducts: employeeAddedProducts
          .filter((product) => empAddProducts.includes(product.productName))
          .map((product) => ({
            productName: product.productName,
            quantity: product.selectedCount || 0,
            subTotal: (product.selectedCount || 0) * product.wholeSalePrice,
          })),
        deliveredBy: employeeId,
        totalAmount: subtotal,
        paymentMode: paymentOption,
        amountPayed: payableAmount,
        Balance: balance,
      };

      const deliveryResponse = await apiService.postCall(
        apiPath.apiCreateDelivery,
        deliveryData
      );

      const updatedQuantities = employeeAddedProducts
        .filter((product) => empAddProducts.includes(product.productName))
        .map((product) => ({
          emp_product_id: product.emp_product_id,
          productName: product.productName,
          availableQuantity:
            product.availableQuantity - (product.selectedCount || 0),
          quantityDelivered: product.selectedCount || 0,
        }));

      await Promise.all(
        updatedQuantities.map(
          async ({ emp_product_id, productName, quantityDelivered }) => {
            await apiService.patchCall(
              `${apiPath.apiUpdateAvailableQuantity}/${emp_product_id}`,
              { productName, quantityDelivered }
            );
          }
        )
      );

      const collectionPayload = {
        date: date,
        customer: filteredShops.find((shop) => shop.shopName === selectedShop)
          ._id,
        employee: employeeId,
        balance: balance,
        totalAmount: subtotal,
      };

      await apiService.postCall(apiPath.apiCreateCollection, collectionPayload);

      const billPreviewResponse = deliveryResponse.billPreview;

      if (billPreviewResponse) {
        const newWindow = window.open("", "_blank");
        newWindow.document.open();
        newWindow.document.write(billPreviewResponse);
        newWindow.document.close();
      } else {
        console.warn("Bill preview is not available.");
      }

      const resetProducts = employeeAddedProducts.map((product) => ({
        ...product,
        selectedCount: 0,
        discount: 0,
      }));

      setEmpAddProducts(resetProducts);
      setSelectedArea(null);
      setSelectedShop("");
      setEmpAddProducts([]);
      setPaymentOption("");
      setPayableAmount(0);
      setBalance(0);
    } catch (error) {
      console.error("Error submitting delivery form:", error.message);
    }
  };

  const handleProductSelection = (productName, selected) => {
    setEmpAddProducts((prevSelected) => {
      if (selected) {
        return [...prevSelected, productName];
      } else {
        return prevSelected.filter((prod) => prod !== productName);
      }
    });
  };

  const handleCountChange = (operation, productName) => {
    setEmployeeAddedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.productName === productName
          ? {
              ...product,
              selectedCount:
                operation === "increment"
                  ? (product.selectedCount || 0) + 1
                  : operation === "decrement" &&
                    (product.selectedCount || 0) > 0
                  ? (product.selectedCount || 0) - 1
                  : product.selectedCount || 0,
            }
          : product
      )
    );
  };

  const handleInputChange = (value, productName) => {
    setEmployeeAddedProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.productName === productName) {
          const count = Math.min(Number(value), product.count);
          return { ...product, selectedCount: count };
        }
        return product;
      })
    );
  };

  useEffect(() => {
    const selectedProducts = employeeAddedProducts.filter((product) =>
      empAddProducts.includes(product.productName)
    );

    let total = 0;
    selectedProducts.forEach((product) => {
      const selectedCount = product.selectedCount || 0;
      const discount = product.discount || 0;
      const effectivePrice =
        product.wholeSalePrice * selectedCount - discount * selectedCount;
      total += effectivePrice;
    });

    setSubtotal(total);
    if (paymentOption === "pay-now") {
      setBalance(0);
      setPayableAmount(total);
    } else {
      setBalance((total - payableAmount).toFixed(2));
    }
  }, [empAddProducts, employeeAddedProducts, payableAmount, paymentOption]);

  const generateBillPDF = (deliveryData) => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Delivery Bill", 105, 10, null, null, "center");

    doc.setFontSize(12);
    doc.text(`Date: ${deliveryData.date}`, 10, 30);
    doc.text(`Shop Name: ${deliveryData.shopName}`, 10, 40);

    const tableColumns = ["Product Name", "Cost (₹)", "Quantity", "Amount (₹)"];
    const tableRows = [];

    deliveryData.deliverdProducts.forEach((product) => {
      const rowData = [
        product.productName,
        product.cost || product.wholeSalePrice,
        product.quantity,
        product.subTotal,
      ];
      tableRows.push(rowData);
    });

    doc.autoTable({
      startY: 50,
      head: [tableColumns],
      body: tableRows,
    });

    const finalY = doc.lastAutoTable.finalY + 10;
    const totalQuantity = deliveryData.deliverdProducts.reduce(
      (sum, product) => sum + product.quantity,
      0
    );
    const totalAmount = deliveryData.deliverdProducts.reduce(
      (sum, product) => sum + product.subTotal,
      0
    );

    doc.text(`Total Quantity: ${totalQuantity}`, 10, finalY);
    doc.text(`Total Amount: ₹${totalAmount}`, 10, finalY + 10);

    const deliveredByName = deliveryData.deliveredByName || "Unknown";
    doc.text(`Delivered By: ${deliveredByName}`, 10, finalY + 20);

    doc.save("Delivery_Bill.pdf");
  };

  const handleDownloadPDF = () => {
    try {
      const loggedInUser = filteredShops.find(
        (shop) => shop.shopName === selectedShop
      );
      const deliveryData = {
        date: date,
        shop: loggedInUser ? loggedInUser._id : "Unknown",
        shopName: selectedShop,
        deliverdProducts: employeeAddedProducts
          .filter((product) => empAddProducts.includes(product.productName))
          .map((product) => ({
            productName: product.productName,
            quantity: product.selectedCount || 0,
            cost: product.wholeSalePrice,
            subTotal: (product.selectedCount || 0) * product.wholeSalePrice,
          })),
        deliveredBy: employeeId,
        deliveredByName: employeeName,
        totalAmount: subtotal,
        paymentMode: paymentOption,
        amountPayed: payableAmount,
        Balance: balance,
      };

      generateBillPDF(deliveryData);
    } catch (error) {
      console.error("Error in downloading PDF:", error.message);
      alert("Failed to generate the bill PDF. Please try again.");
    }
  };

  const deliveryGenerateBillPDF = (deliveryData) => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Delivery Bill", 105, 10, null, null, "center");

    doc.setFontSize(12);
    doc.text(`Date: ${deliveryData.date}`, 10, 30);
    doc.text(`Shop Name: ${deliveryData.shopName}`, 10, 40);

    const tableColumns = ["Product Name", "Quantity", "Amount (Rs)"];
    const tableRows = [];

    deliveryData.deliveredProducts.forEach((product) => {
      const rowData = [product.productName, product.quantity, product.subTotal];
      tableRows.push(rowData);
    });

    doc.autoTable({
      startY: 50,
      head: [tableColumns],
      body: tableRows,
    });

    const finalY = doc.lastAutoTable.finalY + 10;
    const totalQuantity = deliveryData.deliveredProducts.reduce(
      (sum, product) => sum + product.quantity,
      0
    );
    const totalAmount = deliveryData.deliveredProducts.reduce(
      (sum, product) => sum + product.subTotal,
      0
    );

    doc.text(`Total Quantity: ${totalQuantity}`, 10, finalY);
    doc.text(`Total Amount: Rs.${totalAmount}`, 10, finalY + 10);

    const deliveredByName = deliveryData.deliveredByName || "Unknown";
    doc.text(`Delivered By: ${deliveredByName}`, 10, finalY + 20);

    doc.save("Delivery_Bill.pdf");
  };

  const deliveryHandleDownloadPDF = (delivery) => {
    try {
      const deliveryData = {
        date: new Date().toLocaleDateString(),
        shopName: delivery.shop?.shopName,
        deliveredProducts: delivery.deliveredProducts.map((product) => ({
          productName: product.productName,
          quantity: product.quantity,
          subTotal: product.subTotal,
        })),
        deliveredByName: delivery.deliveredBy?.name || "Unknown",
        totalAmount: delivery.totalAmount,
        Balance: delivery.Balance,
      };

      deliveryGenerateBillPDF(deliveryData);
    } catch (error) {
      console.error("Error in downloading PDF:", error.message);
      alert("Failed to generate the bill PDF. Please try again.");
    }
  };

  useEffect(() => {
    getProducts();
    getCustomers();
    getEmployeeProducts();
    integrateData();
    getEmployeeId();
    getDeliveredProducts();
  }, []);

  const getDeliveredProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetDeliveries);
      if (response && response.length > 0) {
        setDeliveredProducts(response);
      } else {
        setDeliveredProducts([]);
      }
    } catch (error) {
      console.error("Error in fetching delivered products:", error.message);
    }
  };

  useEffect(() => {
    if (!deliveredProducts) return;
    const filteredDeliveries = deliveredProducts.filter(
      (product) =>
        (!filterDate || product.date === filterDate) &&
        (!filterArea || product.shop.area.area === filterArea)
    );
    setDeliveredPdf(filteredDeliveries);
  }, [filterArea, filterDate, deliveredProducts]);

  const filteredDeliveries = deliveredProducts.filter(
    (delivery) => delivery.date === date
  );

  const toggleShopVisibility = (shopId, e) => {
    e.stopPropagation();
    setExpandedShops((prevState) => ({
      ...prevState,
      [shopId]: !prevState[shopId],
    }));
  };

  const getEmployeeId = async () => {
    try {
      const loggedInUserId = localStorage.getItem("user_id");

      if (!loggedInUserId) {
        console.error("No user_id found in localStorage.");
        return;
      }

      const response = await apiService.getCall(apiPath.apiGetAllUsers);

      if (!Array.isArray(response)) {
        console.error("Unexpected API response format. Expected an array.");
        return;
      }

      const loggedInUser = response.find(
        (user) => user.user_id === loggedInUserId
      );

      if (loggedInUser) {
        setEmployeeId(loggedInUser._id);
        setEmployeeName(loggedInUser.name);
      } else {
        console.error("Logged-in user not found in the user list.");
      }
    } catch (error) {
      console.error("Error in fetching employeeId:", error.message);
    }
  };

  const getProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetProducts);
      if (response && response.length > 0) {
        setProducts(response);
      }
    } catch (error) {
      console.error("Error in fetching products:", error.message);
    }
  };

  const getCustomers = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && response.length > 0) {
        const customerList = response.filter(
          (user) => user.role === "customer"
        );
        setCustomers(customerList);
      } else {
        console.error("Invalid response structure", response);
      }
    } catch (error) {
      console.error("Error in fetching customers:", error.message);
    }
  };

  const getEmployeeProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetEmployeeProducts);
      const storedUserId = localStorage.getItem("user_id");

      if (response && response.length > 0) {
        const filteredProducts = response.filter(
          (item) => item.employee && item.employee.user_id === storedUserId
        );

        const groupedProducts = filteredProducts.reduce((acc, item) => {
          const existingDateEntry = acc.find(
            (entry) => entry.date === item.date
          );

          if (existingDateEntry) {
            existingDateEntry.totalAmount =
              parseFloat(existingDateEntry.totalAmount) +
              parseFloat(item.totalAmount);

            item.addedProducts.forEach((product) => {
              const existingProduct = existingDateEntry.addedProducts.find(
                (p) => p.productName === product.productName
              );
              if (existingProduct) {
                existingProduct.count += product.count;
                existingProduct.availableQuantity += product.availableQuantity;
                existingProduct.amount += product.amount;
              } else {
                existingDateEntry.addedProducts.push({
                  ...product,
                  emp_product_id: item.emp_product_id,
                });
              }
            });
          } else {
            acc.push({
              date: item.date,
              emp_product_id: item.emp_product_id,
              totalAmount: parseFloat(item.totalAmount),
              addedProducts: item.addedProducts.map((product) => ({
                ...product,
                emp_product_id: item.emp_product_id,
              })),
            });
          }

          return acc;
        }, []);

        setEmployeeProduct(groupedProducts);
        console.log("asdf", groupedProducts);
      }
    } catch (error) {
      console.error("Error in getting Employee Added Products", error.message);
    }
  };

  useEffect(() => {
    if (products.length && employeeProduct.length) {
      integrateData();
    }
  }, [date, products, employeeProduct]);

  const integrateData = () => {
    try {
      if (!products.length || !employeeProduct.length) {
        console.warn("Products or employee products are empty");
        return;
      }

      if (!date) {
        console.warn("Date is not selected");
        return;
      }

      const filteredEmployeeProducts = employeeProduct.filter(
        (empProduct) => empProduct.date === date
      );

      if (!filteredEmployeeProducts.length) {
        console.warn(
          `No employee products found for the selected date: ${date}`
        );
        setEmployeeAddedProducts([]);
        return;
      }

      const mappedProducts = filteredEmployeeProducts.flatMap((empProduct) => {
        if (!Array.isArray(empProduct.addedProducts)) {
          console.warn(
            `No addedProducts found for employee product: ${empProduct._id}`
          );
          return [];
        }

        return empProduct.addedProducts
          .map((addedProduct) => {
            const matchedProduct = products.find(
              (prod) => prod.productName === addedProduct.productName
            );

            if (matchedProduct) {
              return {
                productName: addedProduct.productName,
                count: addedProduct.count,
                emp_product_id: empProduct.emp_product_id,
                availableQuantity:
                  matchedProduct.availableQuantity ||
                  addedProduct.availableQuantity,
                wholeSalePrice: Number(matchedProduct.wholeSalePrice),
                discount: 0,
              };
            }
            return null;
          })
          .filter(Boolean);
      });

      setEmployeeAddedProducts(mappedProducts);
    } catch (error) {
      console.error("Error integrating data:", error.message);
    }
  };

  const handleDiscountChange = (value, productName) => {
    setEmployeeAddedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.productName === productName
          ? { ...product, discount: value }
          : product
      )
    );
  };

  const handleDownloadClick = () => {
    const doc = new jsPDF();

    const date = deliveredPdf[0]?.date || "N/A";
    const area = deliveredPdf[0]?.shop?.area?.area || "N/A";

    const productNames = new Set();
    deliveredPdf.forEach((data) => {
      data.deliveredProducts.forEach((product) => {
        productNames.add(product.productName);
      });
    });

    const tableColumn = ["Customer", ...Array.from(productNames), "Amount"];
    const tableRows = [];

    const totalQuantities = new Array(tableColumn.length).fill(0);
    totalQuantities[0] = "Total";

    deliveredPdf.forEach((data) => {
      const row = new Array(tableColumn.length).fill("*");
      row[0] = data.shop.name;

      let totalAmount = 0;

      data.deliveredProducts.forEach((product) => {
        const productIndex = tableColumn.indexOf(product.productName);
        row[productIndex] = product.quantity;

        totalQuantities[productIndex] += product.quantity;

        totalAmount += product.subTotal;
      });

      row[row.length - 1] = totalAmount;
      totalQuantities[row.length - 1] += totalAmount;
      tableRows.push(row);
    });

    tableRows.push(totalQuantities);

    doc.text(`Date: ${date}    |    Area: ${area}`, 14, 10);

    doc.autoTable({
      startY: 20,
      head: [tableColumn],
      body: tableRows,
      theme: "grid",
      styles: { fontSize: 10, halign: "center" },
      headStyles: { fillColor: [22, 160, 133], halign: "center" },
      footStyles: { fillColor: [22, 160, 133], halign: "center" },
    });

    doc.save(`Delivery_${date}_${area}.pdf`);
    setShowModal(false);
  };

  return (
    <>
      <div className="delivery-pdf">
        <button onClick={() => setShowModal(true)}>Download Deliveries</button>

        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Filter Deliveries</h2>

              <label>Date:</label>
              <input
                type="date"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
                required
              />

              <label>Area:</label>
              <select
                value={filterArea}
                onChange={(e) => setFilterArea(e.target.value)}
                required
              >
                <option value="">Select Area</option>
                {uniqueAreas.map((area, index) => (
                  <option key={index} value={area}>
                    {area}
                  </option>
                ))}
              </select>

              <div className="button-container">
                <button
                  onClick={() => {
                    if (!filterDate || !filterArea) {
                      alert(
                        "Please fill all required fields before downloading."
                      );
                    } else {
                      handleDownloadClick();
                    }
                  }}
                >
                  Download PDF
                </button>

                <button className="cancel" onClick={() => setShowModal(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <form className="customer-order-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="date">{t("form.date")}:</label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>{t("form.area")}:</label>
            <div className="area-button-group">
              {uniqueAreas.length > 0 ? (
                uniqueAreas.map((area) => (
                  <button
                    key={area}
                    type="button"
                    className={`area-button ${
                      selectedArea === area ? "selected" : ""
                    }`}
                    onClick={() => setSelectedArea(area)}
                  >
                    {area}
                  </button>
                ))
              ) : (
                <p>{t("form.loadingAreas")}</p>
              )}
            </div>
          </div>

          {selectedArea && filteredShops.length > 0 && (
            <div className="form-group">
              <div className="shop-and-search">
                <label>{t("form.shopName")}:</label>

                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder={t("form.searchShops")}
                  className="shop-search-input"
                />

                {/* {searchTerm && filteredShopList.length > 0 && (
                  <ul className="shop-dropdown">
                    {filteredShopList.map((shop) => (
                      <li
                        key={shop._id}
                        onClick={() => handleShopSelection(shop)}
                        className="shop-dropdown-item"
                      >
                        {shop.shopName}
                      </li>
                    ))}
                  </ul>
                )} */}
              </div>

              <div className="grid-container">
                {filteredShopList.length > 0 ? (
                  <div className="grid-container">
                    {filteredShopList.map((shop) => (
                      <div key={shop._id} className="shop-item">
                        <div className="shop-header">
                          <input
                            type="radio"
                            id={shop.shopName}
                            name="shopName"
                            value={shop.shopName}
                            onChange={(e) => setSelectedShop(e.target.value)}
                            checked={selectedShop === shop.shopName}
                          />
                          <label htmlFor={shop.shopName}>{shop.shopName}</label>
                          <button
                            type="button"
                            onClick={(e) => toggleShopVisibility(shop._id, e)}
                            className="toggle-button"
                          >
                            {expandedShops[shop._id]
                              ? t("form.collapse")
                              : t("form.expand")}
                          </button>
                        </div>

                        {expandedShops[shop._id] && (
                          <div className="shop-details">
                            {filteredDeliveries
                              .filter(
                                (delivery) => delivery.shop?._id === shop._id
                              )
                              .map((delivery) => (
                                <div
                                  key={delivery._id}
                                  className="delivery-details"
                                >
                                  <p>
                                    {t("form.totalAmount")}: ₹
                                    {delivery.totalAmount}
                                  </p>
                                  <p>
                                    {t("form.deliveredBy")}:{" "}
                                    {delivery.deliveredBy?.name ||
                                      t("form.notAvailable")}
                                  </p>
                                  <p>
                                    {t("form.balance")}: ₹{delivery.Balance}
                                  </p>
                                  <div className="delivered-products">
                                    <h4>{t("form.deliveredProducts")}:</h4>
                                    {delivery.deliveredProducts.length > 0 ? (
                                      <ul>
                                        {delivery.deliveredProducts.map(
                                          (product) => (
                                            <li key={product._id}>
                                              {`${product.productName} * ${product.quantity} = ₹${product.subTotal}`}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : (
                                      <p>{t("form.noProductsDelivered")}</p>
                                    )}
                                  </div>
                                  <span
                                    className="download-button"
                                    onClick={() =>
                                      deliveryHandleDownloadPDF(delivery)
                                    }
                                  >
                                    <FaArrowCircleDown />
                                  </span>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>{t("form.noShopsFound")}</p>
                )}
              </div>
            </div>
          )}

          {selectedShop ? (
            employeeAddedProducts.length > 0 ? (
              <div className="form-group">
                <label>{t("form.productList")}:</label>
                <div>
                  {employeeAddedProducts.map((product) => (
                    <div className="product-item" key={product.productName}>
                      <div className="product-header">
                        <input
                          type="checkbox"
                          id={product.productName}
                          onChange={(e) =>
                            handleProductSelection(
                              product.productName,
                              e.target.checked
                            )
                          }
                          checked={empAddProducts.includes(product.productName)}
                        />
                        <label htmlFor={product.productName}>
                          {`${product.productName} (₹${product.wholeSalePrice})`}
                        </label>

                        <div className="count-container">
                          <button
                            type="button"
                            className={`count-button ${
                              (product.selectedCount || 0) <= 0
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() =>
                              handleCountChange(
                                "decrement",
                                product.productName
                              )
                            }
                          >
                            -
                          </button>

                          <input
                            type="number"
                            value={product.selectedCount || 0}
                            onChange={(e) =>
                              handleInputChange(
                                e.target.value,
                                product.productName
                              )
                            }
                            min="0"
                            max={product.count}
                            className="count-input"
                            disabled={
                              !empAddProducts.includes(product.productName)
                            }
                          />

                          <button
                            type="button"
                            className={`count-button ${
                              (product.selectedCount || 0) >= product.count
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() =>
                              handleCountChange(
                                "increment",
                                product.productName
                              )
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="discount">
                        <label>
                          {t("form.discount")} (₹ {t("form.perUnit")}):
                          <input
                            type="number"
                            className="input"
                            value={product.discount}
                            onChange={(e) =>
                              handleDiscountChange(
                                parseFloat(e.target.value),
                                product.productName
                              )
                            }
                            min="0"
                            step="0.01"
                          />
                        </label>
                      </div>
                      <div className="qnt-and-subtotal">
                        <div>
                          {t("form.availableQuantity")}:
                          {product.availableQuantity -
                            (product.selectedCount || 0)}
                        </div>

                        <div>
                          {t("form.subtotal")}: ₹
                          {product.wholeSalePrice *
                            (product.selectedCount || 0) -
                            product.discount * (product.selectedCount || 0)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div>
                  {t("form.totalAmount")}: ₹{subtotal}
                </div>
                <div className="form-group">
                  <label>{t("form.payment")}:</label>
                  <div className="payment-options">
                    <div className="payment-radio">
                      <input
                        type="radio"
                        id="pay-now"
                        name="payment"
                        value="pay-now"
                        onChange={(e) => setPaymentOption(e.target.value)}
                      />
                      <label htmlFor="pay-now">{t("form.payNow")}</label>
                      {paymentOption === "pay-now" && (
                        <div className="form-group-paynow">
                          <label htmlFor="payable-amount">
                            {t("form.payableAmount")}:
                          </label>
                          <input
                            type="number"
                            id="payable-amount"
                            value={subtotal}
                            onChange={(e) =>
                              setPayableAmount(parseFloat(e.target.value))
                            }
                            min="0"
                            max={subtotal}
                            step="0.01"
                            disabled
                          />
                        </div>
                      )}
                    </div>
                    <div className="payment-radio">
                      <input
                        type="radio"
                        id="pay-later"
                        name="payment"
                        value="pay-later"
                        onChange={(e) => setPaymentOption(e.target.value)}
                      />
                      <label htmlFor="pay-later">{t("form.payLater")}</label>
                    </div>
                    <div className="payment-radio">
                      <input
                        type="radio"
                        id="pay-partial"
                        name="payment"
                        value="pay-partial"
                        onChange={(e) => setPaymentOption(e.target.value)}
                      />
                      <label htmlFor="pay-partial">
                        {t("form.payPartial")}
                      </label>
                      {paymentOption === "pay-partial" && (
                        <div className="form-group-paynow">
                          <label htmlFor="payable-amount">
                            {t("form.payableAmount")}:
                          </label>
                          <input
                            type="number"
                            id="payable-amount"
                            value={payableAmount}
                            onChange={(e) =>
                              setPayableAmount(parseFloat(e.target.value))
                            }
                            min="0"
                            max={subtotal}
                            step="0.01"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>
                    {t("form.balance")}: ₹{balance}
                  </label>
                </div>
              </div>
            ) : (
              <div>{t("form.noProductsAvailable")}</div>
            )
          ) : (
            <div>{t("form.selectShopPrompt")}</div>
          )}
          <div className="submit-download-button">
            <button type="button" onClick={() => handleDownloadPDF()}>
              {t("form.downloadBill")}
            </button>
            <button type="submit">{t("form.submit")}</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Deliveries;
