import React, { useEffect, useState } from "react";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { MdEdit, MdDelete } from "react-icons/md";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Vendor = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    phoneNumber: "",
    role: "vendor",
  });
  const [vendors, setVendors] = useState([]);
  const [editingVendor, setEditingVendor] = useState(null);
  const [availableUsername, setAvailableUsername] = useState([]);
  const [usernameAvailability, setUsernameAvailability] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    getVendors();
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && Array.isArray(response) && response.length > 0) {
        const usernames = response.map((user) => user.username);
        setAvailableUsername(usernames);
      }
    } catch (error) {
      console.error("Error in getting users:", error.message);
    }
  };

  const getVendors = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && response.length > 0) {
        const vendorList = response.filter((user) => user.role === "vendor");
        setVendors(vendorList);
      } else {
        console.error("Invalid response structure", response);
      }
    } catch (error) {
      console.error("Error in fetching vendors:", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "username") {
      if (value.trim() === "") {
        setUsernameAvailability("");
      } else if (availableUsername.includes(value)) {
        setUsernameAvailability("Username is already taken");
      } else {
        setUsernameAvailability("Username is available");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingVendor) {
        await apiService.putCall(
          `${apiPath.apiUpdateUser}/${editingVendor.user_id}`,
          formData
        );
      } else {
        const newVendor = { ...formData };
        await apiService.postCall(apiPath.apiCreateUser, newVendor);
      }

      getVendors();

      setFormData({
        name: "",
        username: "",
        password: "",
        role: "vendor",
        phoneNumber: "",
      });
      setFormVisible(false);
      setEditingVendor(null);
    } catch (error) {
      console.error("Error in submitting vendor:", error.message);
    }
  };

  const handleEdit = (vendor) => {
    setFormVisible(true);
    setEditingVendor(vendor);
    setFormData({
      name: vendor.name,
      username: vendor.username,
      password: vendor.password,
      role: vendor.role,
      phoneNumber: vendor.phoneNumber,
    });
  };

  const handleClose = () => {
    setFormVisible(false);
    setFormData({
      name: "",
      username: "",
      password: "",
      role: "vendor",
      phoneNumber: "",
    });
    setEditingVendor(null);
  };

  const handleDelete = async (vendorId) => {
    try {
      await apiService.deleteCall(`${apiPath.apiDeleteUser}/${vendorId}`);
      const updatedVendors = vendors.filter(
        (vendor) => vendor.user_id !== vendorId
      );
      setVendors(updatedVendors);
    } catch (error) {
      console.error("Error in deletion:", error.message);
    }
  };

  const [passwordVisibility, setPasswordVisibility] = useState(
    vendors.map(() => false)
  );

  const togglePasswordVisibility = (index) => {
    const updatedVisibility = [...passwordVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisibility(updatedVisibility);
  };

  return (
    <div>
      <h1>{t("vendorManagement.title")}</h1>
      <button onClick={() => setFormVisible(true)}>
        {t("vendorManagement.addVendorButton")}
      </button>

      {formVisible && (
        <div className="cus-form-container">
          <button
            type="button"
            onClick={handleClose}
            className="cus-close-button"
          >
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <div className="cus-form-field">
              <label>{t("vendorManagement.form.name")}: </label>
              <input
                type="text"
                name="name"
                className="input"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="cus-form-field">
              <label>{t("vendorManagement.form.username")}:</label>
              <input
                type="text"
                name="username"
                className="input"
                value={formData.username}
                onChange={handleChange}
                required
              />
              {formData.username.trim() && usernameAvailability && (
                <p
                  className={`cus-username-availability ${
                    usernameAvailability.includes("taken")
                      ? "cus-error"
                      : "cus-success"
                  }`}
                >
                  {usernameAvailability}
                </p>
              )}
            </div>
            <div className="cus-form-field">
              <label>{t("vendorManagement.form.password")}: </label>
              <input
                type="password"
                name="password"
                className="input"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="cus-form-field">
              <label>{t("vendorManagement.form.phone")}: </label>
              <input
                type="text"
                name="phoneNumber"
                className="input"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="cus-form-field">
              <label>{t("vendorManagement.form.role")}: </label>
              <input
                type="text"
                name="role"
                className="input"
                value={formData.role}
                readOnly
                disabled
              />
            </div>
            <button type="submit" className="cus-submit-button">
              {editingVendor
                ? t("vendorManagement.form.updateButton")
                : t("vendorManagement.form.submitButton")}
            </button>
          </form>
        </div>
      )}

      <table>
        <thead>
          <tr>
            <th>{t("vendorManagement.table.name")}</th>
            <th>{t("vendorManagement.table.username")}</th>
            <th>{t("vendorManagement.table.password")}</th>
            <th>{t("vendorManagement.table.phone")}</th>
            <th>{t("vendorManagement.table.role")}</th>
            <th>{t("vendorManagement.table.action")}</th>
          </tr>
        </thead>
        <tbody>
          {vendors.map((vendor, index) => (
            <tr key={index}>
              <td>{vendor.name}</td>
              <td>{vendor.username}</td>
              <td>
                <div>
                  {passwordVisibility[index] ? vendor.password : "••••••••"}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => togglePasswordVisibility(index)}
                >
                  {passwordVisibility[index] ? <FaEye /> : <FaEyeSlash />}
                </div>
              </td>
              <td>{vendor.phoneNumber}</td>
              <td>{vendor.role}</td>
              <td>
                <span
                  onClick={() => handleEdit(vendor)}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                >
                  <MdEdit />
                </span>
                <span
                  onClick={() => handleDelete(vendor.user_id)}
                  style={{ cursor: "pointer" }}
                >
                  <MdDelete />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Vendor;
