import React, { useEffect, useState } from "react";
import "../assets/css/Expenditure.css";
import { MdEdit, MdDelete } from "react-icons/md";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { useTranslation } from "react-i18next";

const Expenditure = () => {
  const [showForm, setShowForm] = useState(false);
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [expOrSales, setExpOrSales] = useState("");
  const [amount, setAmount] = useState("");
  const [editingExpenditure, setEditingExpenditure] = useState(null);
  const [expenditures, setExpenditures] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getExpenditure();
  }, []);

  const getExpenditure = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetExpenditure);
      if (response && response.data && response.data.length > 0) {
        setExpenditures(response.data);
      }
    } catch (error) {
      console.error("Error getting expenditure", error.message);
    }
  };

  const handleToggleForm = () => {
    setShowForm((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "date") setDate(value);
    if (name === "expenditure/sales") setExpOrSales(value);
    if (name === "amount") setAmount(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      date,
      expenditure: expOrSales,
      amount,
    };

    try {
      if (editingExpenditure) {
        await apiService.putCall(
          `${apiPath.apiUpdateExpenditure}/${editingExpenditure._id}`,
          payload
        );
      } else {
        await apiService.postCall(apiPath.apiCreateExpenditure, payload);
      }

      setShowForm(false);
      setEditingExpenditure(null);
      setDate(new Date().toISOString().split("T")[0]);
      setExpOrSales("");
      setAmount("");
      getExpenditure();
    } catch (error) {
      console.error("Error saving expenditure", error.message);
    }
  };

  const handleEdit = (item) => {
    setEditingExpenditure(item);
    setDate(item.date);
    setExpOrSales(item.expenditureOrSales);
    setAmount(item.amount);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    try {
      await apiService.deleteCall(`${apiPath.apiDeleteExpenditure}/${id}`);
      getExpenditure();
    } catch (error) {
      console.error("Error deleting expenditure", error.message);
    }
  };

  return (
    <div className="container">
      <button className="open-form-btn" onClick={handleToggleForm}>
        {t("expenditure.button")}
      </button>

      {showForm && (
        <div className="form-container">
          <button className="close-btn" onClick={handleToggleForm}>
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <label htmlFor="date">{t("expenditure.date")}:</label>
            <input
              type="date"
              id="date"
              name="date"
              value={date}
              onChange={handleChange}
              required
            />

            <label htmlFor="expenditure/sales">{t("expenditure.name")}:</label>
            <input
              type="text"
              id="expenditure/sales"
              name="expenditure/sales"
              value={expOrSales}
              onChange={handleChange}
              placeholder={t("expenditure.placeholderName")}
              required
            />

            <label htmlFor="amount">{t("expenditure.amount")}:</label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={amount}
              onChange={handleChange}
              placeholder={t("expenditure.placeholderAmount")}
              required
            />

            <button type="submit">
              {editingExpenditure
                ? t("expenditure.update")
                : t("expenditure.submit")}
            </button>
          </form>
        </div>
      )}

      <table className="expenditure-table">
        <thead>
          <tr>
            <th>{t("expenditure.tableHeaders.date")}</th>
            <th>{t("expenditure.tableHeaders.name")}</th>
            <th>{t("expenditure.tableHeaders.amount")}</th>
            <th>{t("expenditure.tableHeaders.action")}</th>
          </tr>
        </thead>
        <tbody>
          {expenditures.map((item, index) => (
            <tr key={item._id}>
              <td>{item.date}</td>
              <td>{item.expenditure}</td>
              <td>{item.amount}</td>
              <td>
                <span
                  className="action-btn edit-btn"
                  onClick={() => handleEdit(item)}
                >
                  <MdEdit />
                </span>
                <span
                  className="action-btn delete-btn"
                  onClick={() => handleDelete(item._id)}
                >
                  <MdDelete />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Expenditure;
