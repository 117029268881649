import React, { useEffect, useState } from "react";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { useTranslation } from "react-i18next";
import "../assets/css/ProofOfDeliveries.css";
import { MdEdit, MdDelete } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ProofOfDeliveries = () => {
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [orders, setOrders] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendorOrders, setVendorOrders] = useState([]);
  const [updatedOrders, setUpdatedOrders] = useState([]);
  const [vendorDeliveries, setVendorDeliveries] = useState([]);
  const user_id = localStorage.getItem("user_id");
  const [editingDelivery, setEditingDelivery] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    getOrders();
  }, [date]);

  useEffect(() => {
    if (selectedVendor) {
      const filteredOrders = orders.filter(
        (order) => order.user._id === selectedVendor
      );
      setVendorOrders(filteredOrders);
      setUpdatedOrders(
        filteredOrders.flatMap((order) =>
          order.orderdetails.map((detail) => {
            return {
              ...detail,
              shortage: 0,
              originalCount: detail.count,
              amountPerPiece:
                detail.count > 0 ? detail.amount / detail.count : 0,
              originalAmount: detail.amount,
            };
          })
        )
      );
    }
  }, [selectedVendor, orders]);

  //   useEffect(() => {
  //     console.log("up", updatedOrders);
  //   }, [updatedOrders]);

  useEffect(() => {
    getVendorDeliveries();
  }, []);

  const getOrders = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetOrders);
      if (response && response.length > 0) {
        const filteredOrders = response.filter((order) => {
          const orderDate = new Date(order.date).toISOString().split("T")[0];
          return orderDate === date && order.user?.role === "vendor";
        });
        setOrders(filteredOrders);
      }
    } catch (error) {
      console.error("Error in getting orders", error.message);
    }
  };

  const getVendorDeliveries = async () => {
    try {
      const response = await apiService.getCall(
        apiPath.apiGetAllVendorDeliveries
      );
      if (response && response.length > 0) {
        setVendorDeliveries(response);
      }
    } catch (error) {
      console.error("Error in getting vendor deliveries", error.message);
    }
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleVendorChange = (vendorId) => {
    setSelectedVendor(vendorId);
  };

  const handleShortageChange = (index, value) => {
    const updatedOrder = updatedOrders[index];

    const shortageValue = Math.max(value, 0);
    if (value === "") {
      updatedOrder.shortage = 0;
      updatedOrder.count = updatedOrder.originalCount;
      updatedOrder.amount = updatedOrder.originalAmount;
    } else {
      updatedOrder.shortage = shortageValue;
      updatedOrder.count = updatedOrder.originalCount - shortageValue;
      updatedOrder.amount =
        updatedOrder.originalAmount -
        shortageValue * updatedOrder.amountPerPiece;
    }
    const newUpdatedOrders = [...updatedOrders];
    newUpdatedOrders[index] = updatedOrder;
    setUpdatedOrders(newUpdatedOrders);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        date,
        vendor: selectedVendor,
        employee: user_id,
        vendorProductDetails: updatedOrders.map((product) => ({
          productName: product.productName,
          shortage: product.shortage,
          amount: product.amount,
          originalAmount: product.originalAmount,
          crateAndPiece: [
            {
              crates: product.crate || 0,
              pieces: product.count || 0,
            },
          ],
        })),
      };

      if (editingDelivery) {
        await apiService.putCall(
          `${apiPath.apiUpdateVendorDeliveries}/${editingDelivery._id}`,
          payload
        );
      } else {
        await apiService.postCall(apiPath.apiCreateVendorDelivery, payload);
      }

      setSelectedVendor("");
      setUpdatedOrders([]);
      setEditingDelivery(null);
      getVendorDeliveries();
    } catch (error) {
      console.error("Error in submitting form", error.message);
    }
  };

  const handleEdit = (delivery) => {
    setEditingDelivery(delivery);

    const parsedDate = delivery.date ? new Date(delivery.date) : null;
    if (parsedDate && !isNaN(parsedDate.getTime())) {
      setDate(parsedDate.toISOString().split("T")[0]);
    } else {
      console.error("Invalid date value in delivery:", delivery.date);
    }

    setSelectedVendor(delivery.vendor?._id);

    const updatedOrderDetails = delivery.vendorProductDetails?.map(
      (product) => ({
        productName: product.productName,
        shortage: product.shortage,
        amount: product.amount,
        crate: product.crateAndPiece[0]?.crates || 0,
        count: product.crateAndPiece[0]?.pieces || 0,
        originalCount: product.crateAndPiece[0]?.pieces || 0,
      })
    );
    setUpdatedOrders(updatedOrderDetails);
  };

  const handleDelete = async (deliveryId) => {
    try {
      await apiService.deleteCall(
        `${apiPath.apiDeleteVendorDeliveries}/${deliveryId}`
      );
      const updatedVendorDelivery = vendorDeliveries.filter(
        (vendorDelivery) => vendorDelivery._id !== deliveryId,
        setVendorDeliveries(updatedVendorDelivery)
      );
    } catch (error) {
      console.error("Error in deleting Vendor Delivery", error.message);
    }
  };

  const handleDownload = (vendorDelivery) => {
    const doc = new jsPDF();

    doc.setFontSize(12);
    doc.text("PNS AGENCY", 105, 10, { align: "center" });
    doc.setFontSize(10);
    doc.text("33/25 E MANTHOPPU STREET", 105, 15, { align: "center" });
    doc.text("DEVAKOTTAI, Tamil Nadu - 630302", 105, 20, {
      align: "center",
    });

    doc.setFontSize(9);
    doc.text("Vendor Details:", 14, 30);
    doc.text(`Name: ${vendorDelivery.vendor?.name || "N/A"}`, 14, 35);
    doc.text(`Address: ${vendorDelivery.vendor?.address || "N/A"}`, 14, 40);
    doc.text(`Contact: ${vendorDelivery.vendor?.phoneNumber || "N/A"}`, 14, 45);

    const tableColumns = [
      "Sl. No",
      "Item Name",
      "Crates",
      "Pieces",
      "Shortage",
      "Amount(Delivered)",
      "Amount(Ordered)",
    ];

    const tableRows = vendorDelivery.vendorProductDetails.map((item, index) => [
      index + 1,
      item.productName || "N/A",
      item.crateAndPiece[0].crates || 0,
      item.crateAndPiece[0].pieces || 0,
      item.shortage || 0,
      item.amount || 0,
      item.originalAmount || 0,
    ]);

    const totalCrates = vendorDelivery.vendorProductDetails.reduce(
      (sum, item) => sum + (item.crateAndPiece[0].crates || 0),
      0
    );
    const totalPieces = vendorDelivery.vendorProductDetails.reduce(
      (sum, item) => sum + (item.crateAndPiece[0].pieces || 0),
      0
    );

    const totalShortage = vendorDelivery.vendorProductDetails.reduce(
      (sum, item) => sum + (item.shortage || 0),
      0
    );
    const totalDelivered = vendorDelivery.vendorProductDetails.reduce(
      (sum, item) => sum + (item.amount || 0),
      0
    );
    const totalOrdered = vendorDelivery.vendorProductDetails.reduce(
      (sum, item) => sum + (item.originalAmount || 0),
      0
    );

    tableRows.push([
      "Total",
      "",
      totalCrates,
      totalPieces,
      totalShortage,
      `Rs: ${totalDelivered}`,
      `Rs: ${totalOrdered}`,
    ]);

    doc.autoTable({
      startY: 50,
      head: [tableColumns],
      body: tableRows,
      theme: "grid",
      styles: { fontSize: 8 },
      headStyles: { fillColor: [100, 100, 100] },
    });

    // Display total amount text
    const finalY = doc.lastAutoTable.finalY + 10;
    // doc.text(
    //   `Total Amount: Rs: ${vendorDelivery.totalAmount || 0}`,
    //   14,
    //   finalY
    // );

    doc.save(`${vendorDelivery.date}-${vendorDelivery.vendor?.name}.pdf`);
  };

  return (
    <>
      <div>
        <form>
          <div className="pod-form-container">
            <div className="pod-form-group">
              <label htmlFor="date">{t("pod.form.date")}</label>
              <input
                type="date"
                id="date"
                value={date}
                onChange={(e) => handleDateChange(e.target.value)}
                required
              />
            </div>
            <div className="pod-form-group">
              {orders.length > 0 ? (
                <>
                  <label htmlFor="vendor">{t("pod.form.vendor")}</label>
                  <select
                    id="vendor"
                    value={selectedVendor}
                    onChange={(e) => handleVendorChange(e.target.value)}
                    required
                  >
                    <option value="">{t("pod.form.selectVendor")}</option>
                    {orders.map((order) => (
                      <option key={order.user._id} value={order.user._id}>
                        {order.user.name}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <p>{t("pod.form.noOrdersAvailable")}</p>
              )}
            </div>
          </div>

          {selectedVendor && vendorOrders.length > 0 ? (
            <div className="pod-order-details-grid">
              <h3>
                {t("pod.form.orderDetailsFor")} {vendorOrders[0]?.user?.name}
              </h3>

              {updatedOrders.map((detail, index) => (
                <div className="pod-grid-row" key={index}>
                  <span>{detail.productName}</span>
                  <span>
                    {t("pod.form.crate")}: {detail.crate}
                  </span>
                  <span>
                    {t("pod.form.pieces")}: {detail.count}
                  </span>
                  <div>
                    <label>{t("pod.form.shortage")}:</label>
                    <input
                      type="number"
                      min="0"
                      max={detail.count + detail.shortage}
                      value={detail.shortage}
                      onChange={(e) =>
                        handleShortageChange(
                          index,
                          parseInt(e.target.value, 10) || 0
                        )
                      }
                    />
                  </div>
                  <span>
                    {t("pod.form.amount")}: {detail.amount}
                  </span>
                </div>
              ))}
            </div>
          ) : selectedVendor && vendorOrders.length === 0 ? (
            <p>{t("pod.form.noOrdersForVendor")}</p>
          ) : null}

          <div>
            <button className="pod-submit-btn" onClick={handleSubmit}>
              {t("pod.form.submit")}
            </button>
          </div>
        </form>
      </div>
      <div>
        <h2>{t("pod.deliveries.heading")}</h2>
        <div className="pod-table-container">
          <table className="pod-table">
            <thead>
              <tr>
                <th>{t("pod.deliveries.date")}</th>
                <th>{t("pod.deliveries.vendor")}</th>
                <th>{t("pod.deliveries.employee")}</th>
                <th>{t("pod.deliveries.totalShortage")}</th>
                <th>{t("pod.deliveries.amount")}</th>
                <th>{t("pod.deliveries.actions")}</th>
                <th>{t("pod.deliveries.download")}</th>
              </tr>
            </thead>
            <tbody>
              {vendorDeliveries.length > 0 ? (
                vendorDeliveries.map((delivery) => (
                  <tr key={delivery._id}>
                    <td>{delivery.date}</td>
                    <td>{delivery.vendor.name}</td>
                    <td>{delivery.employee.name}</td>
                    <td>{delivery.totalShortage}</td>
                    <td>{delivery.totalAmount}</td>
                    <td>
                      <MdEdit
                        className="action-icon"
                        title={t("pod.deliveries.edit")}
                        onClick={() => handleEdit(delivery)}
                      />
                      <MdDelete
                        className="action-icon"
                        title={t("pod.deliveries.delete")}
                        onClick={() => handleDelete(delivery._id)}
                      />
                    </td>
                    <td>
                      <AiOutlineDownload
                        className="action-icon"
                        title={t("pod.deliveries.download")}
                        onClick={() => handleDownload(delivery)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">{t("pod.deliveries.noDeliveriesFound")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ProofOfDeliveries;
