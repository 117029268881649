import React, { useState, useEffect } from "react";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { useNavigate } from "react-router-dom";
import "../assets/css/Register.css";
import { useTranslation } from "react-i18next";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    phoneNumber: "",
    address: "",
    pincode: "",
    role: "onlineUser",
  });
  const [availableUsername, setAvailableUsername] = useState([]);
  const [usernameAvailability, setUsernameAvailability] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && Array.isArray(response) && response.length > 0) {
        const usernames = response.map((user) => user.username);
        setAvailableUsername(usernames);
      }
    } catch (error) {
      console.error("Error fetching users:", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "username") {
      if (value.trim() === "") {
        setUsernameAvailability("");
      } else if (availableUsername.includes(value)) {
        setUsernameAvailability("Username is already taken");
      } else {
        setUsernameAvailability("Username is available");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await apiService.postCall(apiPath.apiCreateUser, formData);
      setFormData({
        name: "",
        username: "",
        password: "",
        phoneNumber: "",
        address: "",
        pincode: "",
        role: "onlineUser",
      });
      setUsernameAvailability("");
      navigate("/");
    } catch (error) {
      console.error("Error in registering user:", error.message);
    }
  };

  return (
    <div>
      <div className="register-form-container">
        <form className="form" onSubmit={handleSubmit}>
          <p className="title">{t("register.title")}</p>
          <p className="message">{t("register.message")}</p>

          <div style={{ marginBottom: "10px" }}>
            <label>
              <span>{t("register.name")}</span>
              <input
                required
                placeholder=""
                type="text"
                className="input"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </label>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <label>{t("register.username")}</label>
            <input
              type="text"
              name="username"
              className="input"
              value={formData.username}
              onChange={handleChange}
              required
            />
            {formData.username.trim() && usernameAvailability && (
              <p
                style={{
                  color: usernameAvailability.includes("taken")
                    ? "red"
                    : "green",
                }}
              >
                {usernameAvailability}
              </p>
            )}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label>
              <span>{t("register.password")}</span>
              <input
                required
                placeholder=""
                type="password"
                className="input"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </label>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label>{t("register.phoneNumber")}</label>
            <input
              className="input"
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <label>{t("register.address")}</label>
            <input
              type="text"
              name="address"
              className="input"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <label>{t("register.pincode")}</label>
            <input
              type="text"
              name="pincode"
              className="input"
              value={formData.pincode}
              onChange={handleChange}
              required
            />
          </div>

          <button type="submit">{t("register.submit")}</button>
          <p className="signin">
            {t("register.alreadyHaveAccount")}{" "}
            <a href="/login">{t("register.signin")}</a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Register;
