import React, { useEffect, useState } from "react";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../assets/css/CheckoutPage.css";
import { useTranslation } from "react-i18next";

const apiEndpoint = process.env.REACT_APP_apiEndpoint;

const Modal = ({ isVisible, onClose, children }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    navigate("/");
    window.location.reload();
  };

  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {children}
        <button className="close-button" onClick={handleClose}>
          X
        </button>
      </div>
    </div>
  );
};

const CheckoutPage = () => {
  const [cart, setCart] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const user_id = localStorage.getItem("user_id");
  const isGuest = !user_id;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [products, setProducts] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getCart();
    getProducts();
  }, []);

  useEffect(() => {
    const allProductIds = cart.map((item) => item.products._id);
    setSelectedProducts(allProductIds);
  }, [cart]);

  const getProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetProducts);
      if (response && response.length > 0) {
        setProducts(response);
      }
    } catch (error) {
      console.error("Error in fetching products:", error.message);
    }
  };

  const getCart = async () => {
    try {
      if (isGuest) {
        const localCart = JSON.parse(localStorage.getItem("guestCart")) || [];
        const response = await apiService.getCall(apiPath.apiGetProducts);

        if (response && response.length > 0) {
          const formattedCart = localCart
            .map((item) => {
              const product = response.find(
                (prod) => prod._id === item.productId
              );
              return product
                ? {
                    products: product,
                    count: item.quantity,
                    totalVolume: formatVolume(product.quantity) * item.quantity,
                  }
                : null;
            })
            .filter(Boolean);

          setCart(formattedCart);
          setSelectedProducts(formattedCart.map((item) => item.products._id));
        }
      } else {
        const response = await apiService.getCall(
          `${apiPath.apiGetCart}/${user_id}`
        );

        if (response && response.cart && Array.isArray(response.cart)) {
          const formattedCart = response.cart.map((item) => ({
            ...item,
            totalVolume: formatVolume(item.products.quantity) * item.count,
          }));
          setCart(formattedCart);
          setSelectedProducts(formattedCart.map((item) => item.products._id));
        }
      }
    } catch (error) {
      console.error("Error in getting cart", error.message);
    }
  };

  const formatVolume = (quantity) => {
    const quantityValue = parseFloat(quantity);
    if (quantity.includes("millilitre")) {
      return quantityValue / 1000;
    }
    if (quantity.includes("litre")) {
      return quantityValue;
    }
    return 0;
  };

  const handleCheckboxChange = (productId, isChecked) => {
    const updatedSelectedProducts = isChecked
      ? [...selectedProducts, productId]
      : selectedProducts.filter((id) => id !== productId);

    setSelectedProducts(updatedSelectedProducts);
  };

  const updateCount = (productId, newCount) => {
    if (isGuest) {
      const localCart = JSON.parse(localStorage.getItem("guestCart")) || [];
      const updatedCart = localCart.map((item) =>
        item.productId === productId ? { ...item, quantity: newCount } : item
      );
      localStorage.setItem("guestCart", JSON.stringify(updatedCart));
    } else {
      const updatedCart = cart.map((item) =>
        item.products._id === productId
          ? {
              ...item,
              count: newCount,
              totalVolume: formatVolume(item.products.quantity) * newCount,
            }
          : item
      );
      setCart(updatedCart);
    }

    const updatedCart = cart.map((item) =>
      item.products._id === productId
        ? {
            ...item,
            count: newCount,
            totalVolume: formatVolume(item.products.quantity) * newCount,
          }
        : item
    );

    setCart(updatedCart);
  };

  const removeProduct = async (productId) => {
    if (isGuest) {
      const localCart = JSON.parse(localStorage.getItem("guestCart")) || [];
      const updatedCart = localCart.filter(
        (item) => item.productId !== productId
      );
      localStorage.setItem("guestCart", JSON.stringify(updatedCart));
    } else {
      try {
        const payload = {
          userId: user_id,
          productId: productId,
        };

        const response = await apiService.postCall(
          apiPath.apiRemoveFromCart,
          payload
        );

        if (response) {
          const updatedCart = cart.filter(
            (item) => item.products._id !== productId
          );
          setCart(updatedCart);

          const updatedSelectedProducts = selectedProducts.filter(
            (id) => id !== productId
          );
          setSelectedProducts(updatedSelectedProducts);
        }
      } catch (error) {
        console.error("Error in removing product", error.message);
      }
    }
  };

  const clearCart = async () => {
    if (isGuest) {
      localStorage.removeItem("guestCart");
      setCart([]);
      setSelectedProducts([]);
      setShowCheckoutForm(false);
    } else {
      try {
        const response = await apiService.deleteCall(
          `${apiPath.apiClearCart}/${user_id}`
        );

        if (response) {
          setCart([]);
          setSelectedProducts([]);
          setShowCheckoutForm(false);
        }
      } catch (error) {
        console.error("Error in clearing cart", error.message);
      }
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
      setPhoneError("");
    } else {
      setPhoneError(t("enterValidPhoneNumber"));
    }
  };

  const checkout = async (e) => {
    e.preventDefault();
    try {
      if (phoneNumber.length !== 10) {
        setPhoneError("Phone number must be exactly 10 digits.");
        return;
      }

      const selectedItems = cart.filter((item) =>
        selectedProducts.includes(item.products._id)
      );

      const orderDetails = selectedItems.map((item) => ({
        productName: item.products.productName,
        count: item.count,
        amount: item.products.sellingPrice * item.count,
      }));

      const totalAmount = orderDetails.reduce(
        (acc, item) => acc + item.amount,
        0
      );

      if (totalAmount >= 1500) {
        setShowCheckoutForm(true);
      } else {
        alert(t("minimumAmountAlert", { amount: 1500 }));
        return;
      }

      const orderData = {
        date: deliveryDate,
        orderdetails: orderDetails,
        totalAmount: totalAmount,
      };

      await apiService.postCall(apiPath.apiCreateOrder, orderData);

      setModalContent(
        <div className="test">
          <h3>{t("orderSentToAgency", { agency: "PNS Agency" })}</h3>
          <h4>{t("orderSummary")}</h4>
          <ul>
            {orderDetails.map((item, index) => (
              <li key={index}>
                {t("orderItem", {
                  productName: item.productName,
                  count: item.count,
                  amount: item.amount,
                })}
              </li>
            ))}
          </ul>
          <p>{t("totalAmount", { totalAmount })}</p>
          <p>{t("thanksForShopping")}</p>
        </div>
      );

      setShowModal(true);

      const notificationData = {
        deliveryDate: deliveryDate,
        name: name,
        phoneNumber: phoneNumber,
        address: address,
        orderDetails: orderDetails,
      };

      await apiService.postCall(
        apiPath.apiCreateNotification,
        notificationData
      );

      const event = new CustomEvent("notificationsUpdated");
      window.dispatchEvent(event);

      if (!isGuest) {
        for (const productId of selectedProducts) {
          await removeProduct(productId);
        }
      } else {
        localStorage.removeItem("guestCart");
      }

      setSelectedProducts([]);
      setShowCheckoutForm(false);
    } catch (error) {
      console.error("Error in submitting the form", error.message);
    }
  };

  const calculateSelectedTotalAmount = () => {
    return cart
      .filter((item) => selectedProducts.includes(item.products._id))
      .reduce((acc, item) => acc + item.products.sellingPrice * item.count, 0);
  };

  return (
    <div className="checkout-page">
      <>
        <h2>{t("checkout")}</h2>
        <div className="cart-items">
          {cart.length > 0 ? (
            cart.map((item) => (
              <div key={item.products._id} className="cart-item">
                <input
                  type="checkbox"
                  checked={selectedProducts.includes(item.products._id)}
                  onChange={(e) =>
                    handleCheckboxChange(item.products._id, e.target.checked)
                  }
                />
                <img
                  src={`${apiEndpoint}uploads/products/${item.products.productImage}`}
                  alt={item.products.productName}
                  className="product-image"
                />
                <div className="product-details">
                  <h4>{item.products.productName}</h4>
                  <div className="count-delete">
                    <div className="count-update">
                      <button
                        onClick={() =>
                          updateCount(item.products._id, item.count - 1)
                        }
                      >
                        -
                      </button>
                      <span>{item.count}</span>
                      <button
                        onClick={() =>
                          updateCount(item.products._id, item.count + 1)
                        }
                      >
                        +
                      </button>
                    </div>
                    <FaTrashAlt
                      className="delete-icon"
                      onClick={() => removeProduct(item.products._id)}
                    />
                  </div>
                  <div>
                    <p>
                      {t("amount", {
                        amount: item.products.sellingPrice * item.count,
                      })}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>{t("noProducts")}</p>
          )}
        </div>
        <div className="cart-actions">
          <button onClick={clearCart} className="clear-cart">
            {t("clearCart")}
          </button>
          <button
            type="button"
            onClick={() => setShowCheckoutForm(true)}
            className="checkout"
          >
            {t("checkoutAmount", { amount: calculateSelectedTotalAmount() })}
          </button>
        </div>
      </>
      <>
        <Modal
          isVisible={showCheckoutForm}
          onClose={() => setShowCheckoutForm(false)}
        >
          <form onSubmit={checkout} className="checkout-form">
            <div>
              <label>{t("form.date")}</label>
              <input
                type="date"
                value={deliveryDate}
                onChange={(e) => setDeliveryDate(e.target.value)}
                required
              />
            </div>
            <div>
              <label>{t("name")}</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div>
              <label>{t("phoneNumber")}</label>
              <input
                type="tel"
                value={phoneNumber}
                onChange={handlePhoneChange}
                required
              />
              {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
            </div>
            <div>
              <label>{t("address")}</label>
              <textarea
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-checkout">
              {t("submitCheckout")}
            </button>
          </form>
        </Modal>
      </>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default CheckoutPage;
