import React, { useEffect, useState } from "react";
import "../assets/css/Account.css";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { useTranslation } from "react-i18next";

const AccountsComponent = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonthIndex = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [deliveries, setDeliveries] = useState([]);
  const [employeeAddedProducts, setEmployeeAddedProducts] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [role, setRole] = useState("");
  const [expandedShopId, setExpandedShopId] = useState(null);
  const [shopFilter, setShopFilter] = useState("");
  const [employeeFilter, setEmployeeFilter] = useState("");
  const [areaFilter, setAreaFilter] = useState("");
  const [employees, setEmployees] = useState([]);
  const [areas, setAreas] = useState([]);

  const toggleShopDetails = (shopId) => {
    setExpandedShopId((prevId) => (prevId === shopId ? null : shopId));
  };

  const { t } = useTranslation();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    const userId = localStorage.getItem("user_id");

    setRole(userRole);
    fetchData(userRole, userId);
  }, []);

  useEffect(() => {
    const allEmployees = [];
    const allAreas = [];
    Object.values(groupedData).forEach((group) => {
      if (group.shop?.area?.area) allAreas.push(group.shop.area?.area);
      Object.values(group.dates).forEach((dateGroup) => {
        dateGroup.employeeAddedProducts.forEach((product) => {
          if (product.employee?.name) allEmployees.push(product.employee.name);
        });
      });
    });

    setEmployees([...new Set(allEmployees)]);
    setAreas([...new Set(allAreas)]);
  }, [groupedData]);

  const fetchData = async (role, userId) => {
    try {
      const [deliveriesResponse, employeeProductsResponse] = await Promise.all([
        apiService.getCall(apiPath.apiGetDeliveries),
        apiService.getCall(apiPath.apiGetEmployeeProducts),
      ]);

      let filteredDeliveries = deliveriesResponse;
      let filteredEmployeeProducts = employeeProductsResponse;

      if (role === "employee") {
        filteredDeliveries = deliveriesResponse.filter(
          (delivery) => delivery.deliveredBy.user_id === userId
        );
        filteredEmployeeProducts = employeeProductsResponse.filter(
          (product) => product.employee.user_id === userId
        );
      }

      setDeliveries(filteredDeliveries);
      setEmployeeAddedProducts(filteredEmployeeProducts);

      const grouped = groupDataByShopAndDate(
        filteredDeliveries,
        filteredEmployeeProducts
      );

      setGroupedData(grouped);
    } catch (error) {
      console.error("Error fetching data", error.message);
    }
  };

  const groupDataByShopAndDate = (deliveries, employeeProducts) => {
    const grouped = {};

    deliveries.forEach((delivery) => {
      const deliveryDate = new Date(delivery.date).toISOString().split("T")[0];
      if (!grouped[delivery.shop._id]) {
        grouped[delivery.shop._id] = { shop: delivery.shop, dates: {} };
      }

      const shopGroup = grouped[delivery.shop._id];
      if (!shopGroup.dates[deliveryDate]) {
        shopGroup.dates[deliveryDate] = {
          date: deliveryDate,
          totalQuantity: 0,
          totalAmount: 0,
          balance: 0,
          deliveredProducts: [],
          employeeAddedProducts: [],
        };
      }

      const dateGroup = shopGroup.dates[deliveryDate];
      dateGroup.totalQuantity += delivery.deliveredProducts.reduce(
        (sum, product) => sum + product.quantity,
        0
      );
      dateGroup.totalAmount += delivery.totalAmount;
      dateGroup.balance += delivery.Balance;
      dateGroup.deliveredProducts.push(...delivery.deliveredProducts);

      const employeeProductsForDate = employeeProducts.filter(
        (product) => product.date === deliveryDate
      );
      dateGroup.employeeAddedProducts.push(...employeeProductsForDate);
    });
    return grouped;
  };

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setSelectedYear(newYear);
    setSelectedMonth("");
    setSelectedDay("");
  };

  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setSelectedMonth(newMonth);
    setSelectedDay("");
  };

  const handleDayChange = (e) => {
    setSelectedDay(e.target.value);
  };

  const getAvailableDays = (year, month) => {
    if (month === "") return [];
    const daysInMonth = new Date(year, parseInt(month) + 1, 0).getDate();
    return [...Array(daysInMonth).keys()].map((i) => i + 1);
  };

  const filteredGroupedData = Object.values(groupedData).filter((data) => {
    const matchesShop =
      shopFilter === "" ||
      data.shop.shopName.toLowerCase().includes(shopFilter.toLowerCase());
    const matchesEmployee =
      employeeFilter === "" ||
      Object.values(data.dates).some((dateGroup) =>
        dateGroup.employeeAddedProducts.some((product) =>
          product.employee.name
            .toLowerCase()
            .includes(employeeFilter.toLowerCase())
        )
      );
    const matchesArea =
      areaFilter === "" ||
      (data.shop.area?.area &&
        data.shop.area?.area.toLowerCase().includes(areaFilter.toLowerCase()));
    const matchesYear =
      selectedYear === "" ||
      Object.keys(data.dates).some((date) =>
        date.startsWith(selectedYear.toString())
      );
    const matchesMonth =
      selectedMonth === "" ||
      Object.keys(data.dates).some((date) =>
        date.startsWith(
          `${selectedYear}-${String(parseInt(selectedMonth) + 1).padStart(
            2,
            "0"
          )}`
        )
      );
    const matchesDay =
      selectedDay === "" ||
      Object.keys(data.dates).some(
        (date) =>
          date ===
          `${selectedYear}-${String(parseInt(selectedMonth) + 1).padStart(
            2,
            "0"
          )}-${String(selectedDay).padStart(2, "0")}`
      );

    return (
      matchesShop &&
      matchesEmployee &&
      matchesArea &&
      matchesYear &&
      matchesMonth &&
      matchesDay
    );
  });

  const calculateSummary = (data) => {
    let totalPending = 0;
    let totalPayment = 0;
    let productsDelivered = 0;
    let productsAvailable = 0;

    if (!data || data.length === 0)
      return {
        totalPending,
        totalPayment,
        productsDelivered,
        productsAvailable,
      };

    data.forEach((shopGroup) => {
      Object.values(shopGroup.dates).forEach((dateGroup) => {
        totalPending += dateGroup.balance;
        totalPayment += dateGroup.totalAmount;

        productsDelivered += dateGroup.deliveredProducts.reduce(
          (sum, product) => sum + product.quantity,
          0
        );

        dateGroup.employeeAddedProducts.forEach((employeeData) => {
          productsAvailable += employeeData.addedProducts.reduce(
            (sum, product) => sum + product.availableQuantity,
            0
          );
        });
      });
    });

    return { totalPending, totalPayment, productsDelivered, productsAvailable };
  };

  const { totalPending, totalPayment, productsDelivered, productsAvailable } =
    calculateSummary(filteredGroupedData);
  console.log("asdfn", filteredGroupedData);

  return (
    <div className="container">
      <h2>{t("accountsOverview.title")}</h2>

      <div className="filters">
        <div className="date-filters">
          <h5>{t("accountsOverview.filters.dateFilter")}</h5>
          <select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: currentYear - 2023 + 1 }, (_, i) => (
              <option key={i} value={2023 + i}>
                {2023 + i}
              </option>
            ))}
          </select>

          <select value={selectedMonth} onChange={handleMonthChange}>
            <option value="">
              {t("accountsOverview.filters.selectMonth")}
            </option>
            {months.map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </select>

          <select value={selectedDay} onChange={handleDayChange}>
            <option value="">{t("accountsOverview.filters.selectDate")}</option>
            {getAvailableDays(selectedYear, selectedMonth).map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>

        {role === "admin" && (
          <>
            <div className="employee-filters">
              <h5>{t("accountsOverview.filters.employeeFilter")}</h5>
              <select
                value={employeeFilter}
                onChange={(e) => setEmployeeFilter(e.target.value)}
              >
                <option value="">
                  {t("accountsOverview.filters.allEmployees")}
                </option>
                {employees.map((employee, index) => (
                  <option key={index} value={employee}>
                    {employee}
                  </option>
                ))}
              </select>
            </div>
            <div className="employee-filters">
              <h5>{t("accountsOverview.filters.areaFilter")}</h5>
              <select
                value={areaFilter}
                onChange={(e) => setAreaFilter(e.target.value)}
              >
                <option value="">
                  {t("accountsOverview.filters.allAreas")}
                </option>
                {areas.map((area, index) => (
                  <option key={index} value={area}>
                    {area}
                  </option>
                ))}
              </select>
            </div>
            <div className="shop-filters">
              <h5>{t("accountsOverview.filters.shopFilter")}</h5>
              <input
                type="text"
                placeholder={t("accountsOverview.filters.searchByShop")}
                value={shopFilter}
                onChange={(e) => setShopFilter(e.target.value)}
              />
            </div>
          </>
        )}
      </div>

      <div className="summary-cards">
        <div className="card">
          <h4>{t("accountsOverview.summaryCards.totalPendingBalance")}</h4>
          <p>₹{totalPending}</p>
        </div>
        <div className="card">
          <h4>{t("accountsOverview.summaryCards.totalPayment")}</h4>
          <p>₹{totalPayment}</p>
        </div>
        <div className="card">
          <h4>{t("accountsOverview.summaryCards.productsDelivered")}</h4>
          <p>{productsDelivered}</p>
        </div>
        <div className="card">
          <h4>{t("accountsOverview.summaryCards.productsAvailable")}</h4>
          <p>{productsAvailable}</p>
        </div>
      </div>

      <div className="shops-section">
        {filteredGroupedData.map((shopGroup) => (
          <div className="shop-item" key={shopGroup.shop._id}>
            <div
              className="shop-header"
              onClick={() => toggleShopDetails(shopGroup.shop._id)}
            >
              <span>{shopGroup.shop.shopName}</span>
              <span>
                {t("accountsOverview.shopsSection.pending")}: ₹
                {shopGroup.dates[Object.keys(shopGroup.dates)[0]].balance}
              </span>
              <span className="expand-icon">
                {expandedShopId === shopGroup.shop._id
                  ? t("accountsOverview.shopsSection.expandIcon.collapse")
                  : t("accountsOverview.shopsSection.expandIcon.expand")}
              </span>
            </div>
            {expandedShopId === shopGroup.shop._id && (
              <div className="shop-details">
                {Object.values(shopGroup.dates).map((dateGroup) => (
                  <div key={dateGroup.date}>
                    <h5>
                      {t("accountsOverview.shopsSection.date")}:{" "}
                      {dateGroup.date}
                    </h5>
                    <p>
                      {t(
                        "accountsOverview.shopsSection.totalDeliveredQuantity"
                      )}
                      : {dateGroup.totalQuantity}
                    </p>
                    <p>
                      {t("accountsOverview.shopsSection.totalAmount")}: ₹
                      {dateGroup.totalAmount}
                    </p>
                    <p>
                      {t("accountsOverview.shopsSection.balance")}: ₹
                      {dateGroup.balance}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountsComponent;
