import React, { useEffect, useState } from "react";
import { FaShoppingCart, FaFilter } from "react-icons/fa";
import "../assets/css/Userview.css";
import apiPath from "../config/apipath";
import apiService from "../services/apiService";
import { useTranslation } from "react-i18next";

const apiEndpoint = process.env.REACT_APP_apiEndpoint;

const MilkProducts = ({ setCartVisibility }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [cartProductsCount, setCartProductsCount] = useState(0);
  const user_id = localStorage.getItem("user_id");
  const [isLoading, setIsLoading] = useState(true);
  const [prevSelectedCategory, setPrevSelectedCategory] = useState(null);

  useEffect(() => {
    if (prevSelectedCategory !== selectedCategory) {
      setSelectedSubCategory("All Products");
      setPrevSelectedCategory(selectedCategory);
    }
  }, [selectedCategory, prevSelectedCategory]);

  useEffect(() => {
    if (prevSelectedCategory !== selectedCategory) {
      setSelectedSubCategory("All Products");
      setPrevSelectedCategory(selectedCategory);
    }
  }, [selectedCategory, prevSelectedCategory]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getProducts();
      await getCartProducts();
      setIsLoading(false);
    };
    fetchData();
    console.log("env", apiEndpoint);
  }, []);

  useEffect(() => {
    setCartVisibility(true);
    return () => setCartVisibility(false);
  }, [setCartVisibility]);

  useEffect(() => {
    if (products.length > 0) {
      filterProducts();
    }
  }, [
    products,
    searchTerm,
    selectedCategory,
    selectedSubCategory,
    selectedBrand,
  ]);

  const getCartProducts = async () => {
    try {
      const response = await apiService.getCall(
        `${apiPath.apiGetCart}/${user_id}`
      );

      if (response) {
        const cartItems = response.cart || [];
        const cartCount = cartItems.length;
        setCartProductsCount(cartCount);
        window.dispatchEvent(
          new CustomEvent("cartCountChanged", { detail: cartCount })
        );
      } else {
        setCartProductsCount(0);
        console.warn("Cart is empty or response is invalid.");
      }
    } catch (error) {
      console.error("Error in getting Cart Products:", error.message);
      setCartProductsCount(0);
    }
  };

  const getProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetProducts);
      if (response && response.length > 0) {
        setProducts(response);
        setQuantities(
          response.reduce((acc, product) => ({ ...acc, [product._id]: 0 }), {})
        );

        const uniqueCategories = [
          { name: "All Products", count: response.length },
          ...Array.from(
            new Set(response.map((product) => product.category))
          ).map((category) => ({
            name: category,
            count: response.filter((product) => product.category === category)
              .length,
          })),
        ];
        setCategories(uniqueCategories);

        const uniqueBrands = Array.from(
          new Set(response.map((product) => product.vendor.name))
        );
        setBrands(uniqueBrands);

        const uniqueSubCategories = Array.from(
          new Set(response.map((product) => product.subCategory))
        );
        setSubcategories(uniqueSubCategories);
      }
    } catch (error) {
      console.error("Error in fetching products:", error.message);
    }
  };

  const filterProducts = () => {
    if (products.length === 0) return;

    if (prevSelectedCategory !== selectedCategory) {
      setSelectedSubCategory("All Products");
    }

    const filtered = products.filter((product) => {
      const matchesSearch = product.productName
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesCategory =
        !selectedCategory ||
        selectedCategory === "All Products" ||
        product.category === selectedCategory;
      const matchesSubCategory =
        !selectedSubCategory ||
        selectedSubCategory === "All Products" ||
        product.subCategory === selectedSubCategory;
      const matchesBrand =
        !selectedBrand || product.vendor.name === selectedBrand;
      return (
        matchesSearch && matchesCategory && matchesSubCategory && matchesBrand
      );
    });

    setFilteredProducts(filtered);

    if (selectedBrand) {
      const updatedCategories = Array.from(
        new Set(filtered.map((product) => product.category))
      ).map((category) => ({
        name: category,
        count: filtered.filter((product) => product.category === category)
          .length,
      }));
      setCategories(updatedCategories);
      const updatedSubCategories = Array.from(
        new Set(filtered.map((product) => product.subCategory))
      ).map((subCategory) => ({
        name: subCategory,
        count: filtered.filter((product) => product.subCategory === subCategory)
          .length,
      }));
      setSubcategories(updatedSubCategories);
    } else {
      const uniqueCategories = [
        { name: "All Products", count: products.length },
        ...Array.from(new Set(products.map((product) => product.category))).map(
          (category) => ({
            name: category,
            count: products.filter((product) => product.category === category)
              .length,
          })
        ),
      ];
      setCategories(uniqueCategories);
      const uniqueSubCategories = [
        { name: "All Products", count: products.length },
        ...Array.from(
          new Set(products.map((product) => product.subCategory))
        ).map((subCategory) => ({
          name: subCategory,
          count: products.filter(
            (product) => product.subCategory === subCategory
          ).length,
        })),
      ];
      setSubcategories(uniqueSubCategories);
    }
  };

  const closeFilterMenu = () => {
    setShowFilterMenu(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    closeFilterMenu();
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    closeFilterMenu();
  };

  const handleSubCategorySelect = (subCategory) => {
    setSelectedSubCategory(subCategory);
    closeFilterMenu();
  };

  const handleBrandSelect = (e) => {
    setSelectedBrand(e.target.value);
    closeFilterMenu();
  };

  const toggleFilterMenu = () => {
    setShowFilterMenu(!showFilterMenu);
  };

  const increaseQuantity = (id) => {
    setQuantities((prev) => ({ ...prev, [id]: prev[id] + 1 }));
  };

  const decreaseQuantity = (id) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: prev[id] > 0 ? prev[id] - 1 : 0,
    }));
  };

  const handleAddToCart = async (productId) => {
    const quantity = quantities[productId];

    const userId = localStorage.getItem("user_id");

    setQuantities((prev) => ({
      ...prev,
      [productId]: (prev[productId] || 0) + 1,
    }));

    if (!userId) {
      const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
      const existingItemIndex = guestCart.findIndex(
        (item) => item.productId === productId
      );

      if (existingItemIndex > -1) {
        guestCart[existingItemIndex].quantity += quantity;
      } else {
        guestCart.push({ productId, quantity });
      }

      localStorage.setItem("guestCart", JSON.stringify(guestCart));

      const cartCount = guestCart.length;
      const event = new CustomEvent("cartCountChanged", { detail: cartCount });
      window.dispatchEvent(event);
      return;
    }

    try {
      const payload = {
        userId,
        productId,
        quantity: 1,
      };

      const response = await apiService.postCall(apiPath.apiAddToCart, payload);

      if (response) {
        getCartProducts();
      }
    } catch (error) {
      console.error("Error adding product to cart:", error.message);
      alert("Failed to add product to cart. Please try again.");
    }
  };

  return (
    <div className="milk-products-container">
      {isLoading ? (
        <div>{t("loading")}</div>
      ) : (
        <>
          <button
            className={`filter-toggle-btn ${showFilterMenu ? "open" : ""}`}
            onClick={toggleFilterMenu}
          >
            <FaFilter /> {t("filter")}
          </button>

          <div className={`filter-menu ${showFilterMenu ? "open" : ""}`}>
            <button
              className="close-filter-btn"
              onClick={() => setShowFilterMenu(false)}
            >
              &times;
            </button>
            <h3>{t("search")}</h3>
            <input
              type="text"
              placeholder={t("searchPlaceholder")}
              value={searchTerm}
              onChange={handleSearch}
            />

            <h3>{t("brands")}</h3>
            <select value={selectedBrand} onChange={handleBrandSelect}>
              <option value="">{t("allBrands")}</option>
              {brands.map((brand, index) => (
                <option key={index} value={brand}>
                  {brand}
                </option>
              ))}
            </select>

            <h3>{t("categories")}</h3>
            <ul>
              {categories.map((category) => (
                <li
                  key={category.name}
                  className={selectedCategory === category.name ? "active" : ""}
                  onClick={() => handleCategorySelect(category.name)}
                >
                  {category.name} ({category.count})
                </li>
              ))}
            </ul>

            <h3>{t("subcategories")}</h3>
            <ul>
              {subcategories.map((scategory) => (
                <li
                  key={scategory.name}
                  className={
                    selectedSubCategory === scategory.name ? "active" : ""
                  }
                  onClick={() => handleSubCategorySelect(scategory.name)}
                >
                  {scategory.name} ({scategory.count})
                </li>
              ))}
            </ul>
          </div>

          <div className="products">
            <div className="product-grid">
              {filteredProducts.map((product) => (
                <div className="product-card" key={product._id}>
                  <div className="product-content">
                    <img
                      src={`${apiEndpoint}uploads/products/${product.productImage}`}
                      alt={product.productName}
                    />
                    <h4>{product.productName}</h4>
                    <p>₹{product.sellingPrice}</p>
                    <div className="quantity-controls">
                      <button onClick={() => decreaseQuantity(product._id)}>
                        -
                      </button>
                      <span>{quantities[product._id]}</span>
                      <button onClick={() => increaseQuantity(product._id)}>
                        +
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={() => handleAddToCart(product._id)}
                    className="add-to-cart"
                  >
                    <FaShoppingCart /> {t("addToCart")}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MilkProducts;
