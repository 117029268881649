import React, { useEffect, useState } from "react";
import apiService from "../services/apiService";
import apiPath from "../config/apipath";
import { MdEdit, MdDelete } from "react-icons/md";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "../assets/css/Customer.css";

const Customer = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    role: "customer",
    phoneNumber: "",
    shopName: "",
    address: "",
    area: "",
  });
  const [customers, setCustomers] = useState([]);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [areas, setAreas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [areaFilter, setAreaFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState([]);
  const [availableUsername, setAvailableUsername] = useState([]);
  const [usernameAvailability, setUsernameAvailability] = useState("");
  const { t } = useTranslation();
  const rowsPerPage = 7;

  useEffect(() => {
    getCustomers();
    getAreas();
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && Array.isArray(response) && response.length > 0) {
        const usernames = response.map((user) => user.username);
        setAvailableUsername(usernames);
      }
    } catch (error) {
      console.error("Error in getting users:", error.message);
    }
  };

  const getCustomers = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAllUsers);
      if (response && Array.isArray(response)) {
        const customerList = response.filter(
          (user) => user.role === "customer"
        );
        setCustomers(customerList);
        console.log("customers", customerList);
        setPasswordVisibility(new Array(customerList.length).fill(false));
      }
    } catch (error) {
      console.error("Error in fetching customers:", error.message);
    }
  };

  const getAreas = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAreas);
      if (response && Array.isArray(response)) {
        setAreas(response);
      }
    } catch (error) {
      console.error("Error fetching areas:", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "username") {
      if (value.trim() === "") {
        setUsernameAvailability("");
      } else if (availableUsername.includes(value)) {
        setUsernameAvailability("Username is already taken");
      } else {
        setUsernameAvailability("Username is available");
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const handleAreaFilterChange = (e) => {
    setAreaFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingCustomer) {
        await apiService.putCall(
          `${apiPath.apiUpdateUser}/${editingCustomer.user_id}`,
          formData
        );
      } else {
        await apiService.postCall(apiPath.apiCreateUser, formData);
      }

      getCustomers();
      resetForm();
    } catch (error) {
      console.error("Error in submitting customer:", error.message);
    }
  };

  const handleEdit = (customer) => {
    setFormVisible(true);
    setEditingCustomer(customer);
    setFormData({
      name: customer.name,
      username: customer.username,
      password: customer.password,
      role: customer.role,
      phoneNumber: customer.phoneNumber,
      shopName: customer.shopName,
      address: customer.address,
      area: customer.area?._id || "",
    });
  };

  const handleClose = () => {
    resetForm();
  };

  const resetForm = () => {
    setFormVisible(false);
    setFormData({
      name: "",
      username: "",
      password: "",
      role: "customer",
      phoneNumber: "",
      shopName: "",
      address: "",
      area: "",
    });
    setEditingCustomer(null);
  };

  const handleDelete = async (vendorId) => {
    try {
      await apiService.deleteCall(`${apiPath.apiDeleteUser}/${vendorId}`);
      setCustomers((prev) =>
        prev.filter((customer) => customer.user_id !== vendorId)
      );
    } catch (error) {
      console.error("Error in deletion:", error.message);
    }
  };

  const togglePasswordVisibility = (index) => {
    setPasswordVisibility((prev) =>
      prev.map((visibility, i) => (i === index ? !visibility : visibility))
    );
  };

  const filteredCustomers = customers.filter((customer) => {
    const matchesArea = areaFilter ? customer.area?._id === areaFilter : true;
    const matchesSearch = searchQuery
      ? customer.name.toLowerCase().includes(searchQuery) ||
        customer.shopName.toLowerCase().includes(searchQuery)
      : true;
    return matchesArea && matchesSearch;
  });

  const paginatedCustomers = filteredCustomers.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);

  return (
    <div>
      <h1>{t("customerManagement.title")}</h1>
      <button onClick={() => setFormVisible(true)}>
        {t("customerManagement.addCustomerButton")}
      </button>

      {formVisible && (
        <div className="cus-form-container">
          <button
            type="button"
            onClick={handleClose}
            className="cus-close-button"
          >
            &times;
          </button>

          <form onSubmit={handleSubmit} className="cus-form">
            {["name", "password", "phoneNumber", "shopName", "address"].map(
              (field) => (
                <div key={field} className="cus-form-field">
                  <label>{t(`customerManagement.form.${field}`)}:</label>
                  <input
                    type={field === "password" ? "password" : "text"}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    required
                  />
                </div>
              )
            )}
            <div className="cus-form-field">
              <label>{t("customerManagement.form.username")}:</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
              />
              {formData.username.trim() && usernameAvailability && (
                <p
                  className={`cus-username-availability ${
                    usernameAvailability.includes("taken")
                      ? "cus-error"
                      : "cus-success"
                  }`}
                >
                  {usernameAvailability}
                </p>
              )}
            </div>
            <div className="cus-form-field">
              <label>{t("customerManagement.form.area")}:</label>
              <select
                name="area"
                value={formData.area}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  {t("customerManagement.form.selectArea")}
                </option>
                {areas.map((area) => (
                  <option key={area._id} value={area._id}>
                    {area.area}
                  </option>
                ))}
              </select>
            </div>
            <div className="cus-form-field">
              <label>{t("customerManagement.form.role")}:</label>
              <input
                type="text"
                name="role"
                value="customer"
                readOnly
                disabled
              />
            </div>
            <button type="submit" className="cus-submit-button">
              {editingCustomer
                ? t("customerManagement.form.updateButton")
                : t("customerManagement.form.submitButton")}
            </button>
          </form>
        </div>
      )}

      <div style={{ margin: "20px 0" }}>
        <select value={areaFilter} onChange={handleAreaFilterChange}>
          <option value="">{t("customerManagement.filters.allAreas")}</option>
          {areas.map((area) => (
            <option key={area._id} value={area._id}>
              {area.area}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder={t("customerManagement.filters.searchPlaceholder")}
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      <table border="1">
        <thead>
          <tr>
            <th>{t("customerManagement.table.serialNo")}</th>
            <th>{t("customerManagement.table.name")}</th>
            <th>{t("customerManagement.table.username")}</th>
            <th>{t("customerManagement.table.password")}</th>
            <th>{t("customerManagement.table.phone")}</th>
            <th>{t("customerManagement.table.area")}</th>
            <th>{t("customerManagement.table.action")}</th>
          </tr>
        </thead>
        <tbody>
          {paginatedCustomers.map((customer, index) => (
            <tr key={customer.user_id}>
              <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
              <td>{customer.name}</td>
              <td>{customer.username}</td>
              <td>
                {passwordVisibility[index] ? customer.password : "••••••••"}
                <span onClick={() => togglePasswordVisibility(index)}>
                  {passwordVisibility[index] ? <FaEye /> : <FaEyeSlash />}
                </span>
              </td>
              <td>{customer.phoneNumber}</td>
              <td>
                {customer.area?.area ||
                  t("customerManagement.table.notAvailable")}
              </td>
              <td>
                <MdEdit onClick={() => handleEdit(customer)} />
                <MdDelete onClick={() => handleDelete(customer.user_id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ marginTop: "10px" }}>
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          {t("customerManagement.pagination.previous")}
        </button>
        <span>
          {t("customerManagement.pagination.page")} {currentPage}{" "}
          {t("customerManagement.pagination.of")} {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          {t("customerManagement.pagination.next")}
        </button>
      </div>
    </div>
  );
};

export default Customer;
