import React, { useState } from "react";
import "../assets/css/EmployeeTask.css";
import Deliveries from "./Deliveries";
import EmployeeAddedProducts from "./EmployeeAddedProducts";
import { useTranslation } from "react-i18next";

const EmployeeTask = () => {
  const [activeComponent, setActiveComponent] = useState(null);
  const { t } = useTranslation();

  const handleDeliveryClick = () => {
    setActiveComponent("Deliveries");
  };

  const handleProductsClick = () => {
    setActiveComponent("Products");
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "Deliveries":
        return <Deliveries />;
      case "Products":
        return <EmployeeAddedProducts />;
      default:
        return true;
    }
  };

  return (
    <div className="employee-task-container">
      <div className="grid-container">
        <div className="grid-item">
          <button onClick={handleDeliveryClick}>
            {t("employeeTaskContainer.buttons.deliveries")}
          </button>
        </div>
        <div className="grid-item">
          <button onClick={handleProductsClick}>
            {t("employeeTaskContainer.buttons.products")}
          </button>
        </div>
      </div>
      <div className="content-container">{renderComponent()}</div>
    </div>
  );
};

export default EmployeeTask;
