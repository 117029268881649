import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import "chart.js/auto";
import "../assets/css/Dashboard.css";
import apiPath from "../config/apipath";
import apiService from "../services/apiService";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonthIndex = currentDate.getMonth();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [deliveries, setDeliveries] = useState([]);
  const [orders, setOrders] = useState([]);
  const [lineData, setLineData] = useState({ labels: [], datasets: [] });
  const [barData, setBarData] = useState({ labels: [], datasets: [] });
  const [productOrders, setProductOrders] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [areas, setAreas] = useState([]);
  const [products, setProducts] = useState([]);
  const [sales, setSales] = useState([]);
  const [expenditures, setExpenditures] = useState([]);
  const [filters, setFilters] = useState({
    year: currentYear,
    month: "All",
    day: "All",
    area: "All",
  });
  const { t } = useTranslation();
  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [totalExpenditure, setTotalExpenditure] = useState(0);
  const [shopSales, setShopSales] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    getExpenditure();
    getSales();
  }, [filters]);

  useEffect(() => {
    const updateDashboardData = () => {
      const filteredDeliveries = filterDeliveries(deliveries);
      const filteredOrders = filterOrders(orders);

      const pendingBalanceData =
        calculatePendingBalanceByMonth(filteredDeliveries);
      updateLineChart(pendingBalanceData);

      const salesCountData = calculateMonthlySalesCount(filteredDeliveries);
      updateBarChart(salesCountData);

      const productData = calculateProductOrders(filteredDeliveries);
      setProductOrders(productData);

      const employeeData = calculateEmployeeData(filteredDeliveries);
      setEmployees(employeeData);

      const shopBalanceData = calculateShopBalanceData(filteredDeliveries);
      setShopSales(shopBalanceData);

      setOrders(filteredOrders);
    };

    updateDashboardData();
  }, [filters, deliveries, productOrders]);

  useEffect(() => {
    if (currentMonthIndex === 0 && filters.year !== currentYear) {
      setFilters((prev) => ({ ...prev, year: currentYear }));
    }
  }, [currentMonthIndex, filters.year, currentYear]);

  const getAreas = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetAreas);
      if (response && response.length > 0) {
        setAreas(response);
      }
    } catch (error) {
      console.error("Error in getting Areas", error.message);
    }
  };

  const getProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetProducts);
      if (response && response.length > 0) {
        setProducts(response);
      }
    } catch (error) {
      console.error("Error in getting products", error.message);
    }
  };

  const getExpenditure = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetExpenditure);
      if (response && response.data && response.data.length > 0) {
        const filteredExpenditures = response.data.filter((expenditure) => {
          const expenditureDate = new Date(expenditure.date);
          const matchesYear =
            filters.year === "All" ||
            expenditureDate.getFullYear() === parseInt(filters.year);
          const matchesMonth =
            filters.month === "All" ||
            months[expenditureDate.getMonth()] === filters.month;
          const matchesDay =
            filters.day === "All" ||
            expenditureDate.getDate() === parseInt(filters.day);

          return matchesYear && matchesMonth && matchesDay;
        });

        const totalExpenditureAmount = filteredExpenditures.reduce(
          (sum, expenditure) => sum + expenditure.amount,
          0
        );

        setExpenditures(filteredExpenditures);
        setTotalExpenditure(totalExpenditureAmount);
      }
    } catch (error) {
      console.error("Error getting expenditure", error.message);
    }
  };

  const getSales = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetSales);
      if (response && response.data && response.data.length > 0) {
        const filteredSales = response.data.filter((sale) => {
          const saleDate = new Date(sale.date);
          const matchesYear =
            filters.year === "All" ||
            saleDate.getFullYear() === parseInt(filters.year);
          const matchesMonth =
            filters.month === "All" ||
            months[saleDate.getMonth()] === filters.month;
          const matchesDay =
            filters.day === "All" ||
            saleDate.getDate() === parseInt(filters.day);

          return matchesYear && matchesMonth && matchesDay;
        });

        const totalSalesAmount = filteredSales.reduce(
          (sum, sale) => sum + sale.amount,
          0
        );

        setSales(filteredSales);
        setTotalSalesAmount(totalSalesAmount);
      }
    } catch (error) {
      console.error("Error getting sales", error.message);
    }
  };

  const getDeliveries = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetDeliveries);
      if (response && response.length > 0) {
        const filteredDeliveries = filterDeliveries(response);
        setDeliveries(filteredDeliveries);
        const pendingBalanceData = calculatePendingBalanceByMonth(response);
        updateLineChart(pendingBalanceData);

        const salesCountData = calculateMonthlySalesCount(response);
        updateBarChart(salesCountData);

        const productData = calculateProductOrders(response);
        setProductOrders(productData);

        const employeeData = calculateEmployeeData(response);
        setEmployees(employeeData);

        const shopBalanceData = calculateShopBalanceData(response);
        setShopSales(shopBalanceData);
      }
    } catch (error) {
      console.error("Error fetching deliveries:", error.message);
    }
  };

  const getOrders = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetOrders);
      if (response && response.length > 0) {
        const filteredOrders = filterOrders(response);
        setOrders(filteredOrders);
      }
    } catch (error) {
      console.error("Error in fetching orders:", error.message);
    }
  };

  const vendorOrders = orders.filter(
    (order) => order.user && order.user.role === "vendor"
  );

  const totalOrderQuantity = vendorOrders.reduce((total, order) => {
    const orderTotal = order.orderdetails.reduce(
      (sum, item) => sum + item.count,
      0
    );
    return total + orderTotal;
  }, 0);

  const salesQty = deliveries.reduce((totalQty, delivery) => {
    const deliveryQty = delivery.deliveredProducts.reduce(
      (sum, product) => sum + product.quantity,
      0
    );
    return totalQty + deliveryQty;
  }, 0);

  const totalAmountPaid = deliveries.reduce(
    (sum, delivery) => sum + delivery.amountPayed,
    0
  );

  const totalSales = deliveries.reduce(
    (sum, delivery) => sum + delivery.totalAmount,
    0
  );

  const pendingBalance = deliveries.reduce(
    (sum, delivery) => sum + delivery.Balance,
    0
  );

  const calculateDeliveredCostPrice = (deliveredProducts, products) => {
    return deliveredProducts.map((delivery) => {
      const updatedDeliveredProducts = delivery.deliveredProducts.map(
        (deliveredProduct) => {
          const product = products.find(
            (prod) => prod.productName === deliveredProduct.productName
          );

          if (product) {
            const costPriceForDeliveredProduct =
              product.costPrice * deliveredProduct.quantity;
            return {
              ...deliveredProduct,
              costPrice: costPriceForDeliveredProduct,
            };
          }

          return deliveredProduct;
        }
      );

      const totalCostPrice = updatedDeliveredProducts.reduce(
        (sum, product) => sum + (product.costPrice || 0),
        0
      );

      return {
        ...delivery,
        deliveredProducts: updatedDeliveredProducts,
        totalCostPrice,
      };
    });
  };

  const updatedDeliveries = calculateDeliveredCostPrice(deliveries, products);

  const totalCostPrice = updatedDeliveries.reduce((total, delivery) => {
    const deliveryCost = delivery.deliveredProducts.reduce(
      (sum, product) => sum + product.costPrice,
      0
    );
    return total + deliveryCost;
  }, 0);

  const profit = totalSales - totalCostPrice;

  const productsLeftOver = totalOrderQuantity - salesQty;

  const cardData = {
    orderQty: totalOrderQuantity,
    salesQty: salesQty,
    totalAmountPaid: totalAmountPaid,
    totalSales: totalSales,
    pendingBalance: pendingBalance,
    profit: profit,
    productsLeftOver: productsLeftOver,
    totalExpenditure: totalExpenditure,
    otherSales: totalSalesAmount,
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = async () => {
    await getAreas();
    await getOrders();
    await getDeliveries();
  };

  const calculatePendingBalanceByMonth = (deliveries) => {
    const pendingBalances = deliveries.reduce((acc, delivery) => {
      const monthIndex = new Date(delivery.date).getMonth();
      const month = months[monthIndex];
      acc[month] = (acc[month] || 0) + delivery.Balance;
      return acc;
    }, {});

    return {
      labels: Object.keys(pendingBalances),
      data: Object.values(pendingBalances),
    };
  };

  const updateLineChart = (pendingBalanceData) => {
    setLineData({
      labels: pendingBalanceData.labels,
      datasets: [
        {
          label: "Pending Balance Over Months",
          data: pendingBalanceData.data,
          borderColor: "#134d14",
          backgroundColor: "rgba(19, 77, 20, 0.2)",
          fill: true,
        },
      ],
    });
  };

  const calculateMonthlySalesCount = (deliveries) => {
    const salesCounts = deliveries.reduce((acc, delivery) => {
      const monthIndex = new Date(delivery.date).getMonth();
      const month = months[monthIndex];
      const totalQuantity = delivery.deliveredProducts.reduce(
        (sum, product) => sum + product.quantity,
        0
      );
      acc[month] = (acc[month] || 0) + totalQuantity;
      return acc;
    }, {});

    return {
      labels: Object.keys(salesCounts),
      data: Object.values(salesCounts),
    };
  };

  const updateBarChart = (salesCountData) => {
    setBarData({
      labels: salesCountData.labels,
      datasets: [
        {
          label: "Monthly Sales Count",
          data: salesCountData.data,
          backgroundColor: "#134d14",
        },
      ],
    });
  };

  const calculateProductOrders = (deliveries) => {
    const productMap = {};

    deliveries.forEach((delivery) => {
      delivery.deliveredProducts.forEach((product) => {
        const productName = product.productName || "Unknown Product";
        productMap[productName] =
          (productMap[productName] || 0) + product.quantity;
      });
    });

    return Object.entries(productMap).map(([product, count]) => ({
      product,
      count,
    }));
  };

  const calculateEmployeeData = (deliveries) => {
    const employeeMap = {};

    deliveries.forEach((delivery) => {
      const employee = delivery.deliveredBy || {};
      const employeeName = employee?.name || "Unknown Employee";

      if (!employeeMap[employeeName]) {
        employeeMap[employeeName] = {
          name: employeeName,
          amount: 0,
          pendingBalance: 0,
        };
      }

      employeeMap[employeeName].amount += delivery.amountPayed || 0;
      employeeMap[employeeName].pendingBalance += delivery.Balance || 0;
    });

    return Object.values(employeeMap);
  };

  const calculateShopBalanceData = (deliveries) => {
    const shopBalance = {};

    deliveries.forEach((delivery) => {
      const shop = delivery.shop || {};
      const shopName = shop?.shopName || "Unknown Shop";

      if (!shopBalance[shopName]) {
        shopBalance[shopName] = {
          name: shopName,
          pendingBalance: 0,
        };
      }
      shopBalance[shopName].pendingBalance += delivery.Balance || 0;
    });
    return Object.values(shopBalance);
  };

  const filterOrders = (orders) => {
    return orders.filter((order) => {
      const orderDate = new Date(order.date);
      const matchesYear =
        filters.year === "All" ||
        orderDate.getFullYear() === parseInt(filters.year);
      const matchesMonth =
        filters.month === "All" ||
        months[orderDate.getMonth()] === filters.month;
      const matchesDay =
        filters.day === "All" || orderDate.getDate() === parseInt(filters.day);
      return matchesYear && matchesMonth && matchesDay;
    });
  };

  const filterDeliveries = (deliveries) => {
    return deliveries.filter((delivery) => {
      const deliveryDate = new Date(delivery.date);
      const matchesYear =
        filters.year === "All" ||
        deliveryDate.getFullYear() === parseInt(filters.year);
      const matchesMonth =
        filters.month === "All" ||
        months[deliveryDate.getMonth()] === filters.month;
      const matchesDay =
        filters.day === "All" ||
        deliveryDate.getDate() === parseInt(filters.day);
      const matchesArea =
        filters.area === "All" || delivery.shop.area.area === filters.area;
      return matchesYear && matchesMonth && matchesDay && matchesArea;
    });
  };

  return (
    <div className="dashboard">
      <h2>{t("dashboard.title")}</h2>

      <div className="filters">
        <select
          value={filters.year}
          onChange={(e) => setFilters({ ...filters, year: e.target.value })}
        >
          <option value="All">{t("dashboard.filters.allYears")}</option>
          {[...Array(currentYear - 2022 + 1)].map((_, i) => (
            <option key={i} value={2023 + i}>
              {2023 + i}
            </option>
          ))}
        </select>
        <select
          value={filters.month}
          onChange={(e) => setFilters({ ...filters, month: e.target.value })}
        >
          <option value="All">{t("common.months.all")}</option>
          {months.map((month, index) => (
            <option key={index} value={month}>
              {month}
            </option>
          ))}
        </select>
        <select
          value={filters.day}
          onChange={(e) => setFilters({ ...filters, day: e.target.value })}
        >
          <option value="All">{t("dashboard.filters.allDays")}</option>
          {[...Array(31)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
        <select
          value={filters.area}
          onChange={(e) => setFilters({ ...filters, area: e.target.value })}
        >
          <option value="All">{t("dashboard.filters.allAreas")}</option>
          {areas.map((area) => (
            <option key={area._id} value={area.area}>
              {area.area}
            </option>
          ))}
        </select>
      </div>

      <div className="cards">
        {Object.entries(cardData).map(([key, value]) => (
          <div key={key} className="card">
            {/* <h3>{key.replace(/([A-Z])/g, " $1").toUpperCase()}</h3> */}
            <h3>{t(`dashboard.cards.${key}`)}</h3>
            <p>{value}</p>
          </div>
        ))}
      </div>

      <div className="charts">
        <div className="chart">
          <h3>{t("dashboard.charts.pendingBalance")}</h3>
          <Line data={lineData} />
        </div>
        <div className="chart">
          <h3>{t("dashboard.charts.monthlySales")}</h3>
          <Bar data={barData} />
        </div>
      </div>

      <div className="tables">
        <div className="table">
          <h3>{t("dashboard.tables.productOrders")}</h3>
          <table>
            <thead>
              <tr>
                <th>{t("dashboard.tables.headers.product")}</th>
                <th>{t("dashboard.tables.headers.count")}</th>
              </tr>
            </thead>
            <tbody>
              {productOrders.map((item, index) => (
                <tr key={index}>
                  <td>{item.product}</td>
                  <td>{item.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table">
          <h3>{t("dashboard.tables.employeesAndAmount")}</h3>
          <table>
            <thead>
              <tr>
                <th>{t("dashboard.tables.headers.employee")}</th>
                <th>{t("dashboard.tables.headers.amount")}</th>
                <th>{t("dashboard.tables.headers.balance")}</th>
              </tr>
            </thead>
            <tbody>
              {employees.map((emp, index) => (
                <tr key={index}>
                  <td>{emp.name}</td>
                  <td>{emp.amount}</td>
                  <td>{emp.pendingBalance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table">
          <h3>{t("dashboard.tables.employeesAndAmount")}</h3>
          <table>
            <thead>
              <tr>
                <th>{t("dashboard.tables.headers.shops")}</th>
                <th>{t("dashboard.tables.headers.balance")}</th>
              </tr>
            </thead>
            <tbody>
              {shopSales.map((emp, index) => (
                <tr key={index}>
                  <td>{emp.name}</td>
                  <td>{emp.pendingBalance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
