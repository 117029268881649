import React, { useEffect, useState } from "react";
import apiPath from "../config/apipath";
import apiService from "../services/apiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../assets/css/EmployeeAddedProducts.css";

const EmployeeAddedProducts = () => {
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [emp, setEmp] = useState(() => {
    return localStorage.getItem("role") === "employee"
      ? localStorage.getItem("user_id")
      : null;
  });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [products, setProducts] = useState([]);
  const [employeeProduct, setEmployeeProduct] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getProducts();
    getEmployeeProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetProducts);
      if (response && response.length > 0) {
        setProducts(response);
      }
    } catch (error) {
      console.error("Error in fetching products:", error.message);
    }
  };

  const getEmployeeProducts = async () => {
    try {
      const response = await apiService.getCall(apiPath.apiGetEmployeeProducts);
      const storedUserId = localStorage.getItem("user_id");

      if (response && response.length > 0) {
        const filteredProducts = response.filter(
          (item) => item.employee && item.employee.user_id === storedUserId
        );

        const allAddedProducts = filteredProducts.flatMap(
          (product) => product.addedProducts
        );

        const mergedAddedProducts = Object.values(
          allAddedProducts.reduce((acc, item) => {
            if (!acc[item.productName]) {
              acc[item.productName] = { ...item };
            } else {
              acc[item.productName].count += item.count;
              acc[item.productName].availableQuantity += item.availableQuantity;
              acc[item.productName].amount += item.amount;
            }
            return acc;
          }, {})
        );

        const mergedProduct = {
          _id: "merged",
          emp_product_id: "MERGED_PRODUCTS",
          employee: filteredProducts[0]?.employee,
          date: new Date().toISOString().split("T")[0],
          addedProducts: mergedAddedProducts,
          totalAmount: mergedAddedProducts.reduce(
            (sum, product) => sum + product.amount,
            0
          ),
          isActive: true,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };

        setEmployeeProduct([mergedProduct]);
      }
    } catch (error) {
      console.error("Error in getting Employee Added Products", error.message);
    }
  };

  useEffect(() => {
    const filtered = employeeProduct.filter((item) => item.date === date);
    setFilteredProducts(filtered);
  }, [date, employeeProduct]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleCheckboxChange = (product) => {
    const isAlreadySelected = selectedProducts.some(
      (item) => item.productId === product._id
    );

    if (isAlreadySelected) {
      setSelectedProducts((prev) =>
        prev.filter((item) => item.productId !== product._id)
      );
    } else {
      setSelectedProducts((prev) => [
        ...prev,
        {
          productId: product._id,
          productName: product.productName,
          count: 1,
          amount: parseFloat(product.wholeSalePrice || 0),
        },
      ]);
    }
  };

  const handleCountChange = (productId, delta) => {
    const updatedProducts = selectedProducts.map((product) => {
      if (product.productId === productId) {
        const newCount = product.count + delta;
        const productInfo = products.find((p) => p._id === productId);
        return {
          ...product,
          count: newCount,
          amount: newCount * parseFloat(productInfo.wholeSalePrice || 0),
        };
      }
      return product;
    });
    setSelectedProducts(updatedProducts);

    setTotalAmount(
      updatedProducts.reduce((sum, product) => sum + product.amount, 0)
    );
  };

  const handleInputChange = (productId, value) => {
    if (value === "") {
      const updatedProducts = selectedProducts.map((product) => {
        if (product.productId === productId) {
          return { ...product, count: "", amount: 0 };
        }
        return product;
      });
      setSelectedProducts(updatedProducts);
      setTotalAmount(
        updatedProducts.reduce((sum, product) => sum + product.amount, 0)
      );
      return;
    }

    const count = parseInt(value, 10);
    if (isNaN(count) || count < 0) return;

    const updatedProducts = selectedProducts.map((product) => {
      if (product.productId === productId) {
        const productInfo = products.find((p) => p._id === productId);
        return {
          ...product,
          count,
          amount: count * parseFloat(productInfo.wholeSalePrice || 0),
        };
      }
      return product;
    });
    setSelectedProducts(updatedProducts);

    setTotalAmount(
      updatedProducts.reduce((sum, product) => sum + product.amount, 0)
    );
  };

  const handleEmpAddProduct = async (e) => {
    e.preventDefault();

    try {
      const employeeAddedProductData = {
        employee: emp,
        date: date,
        addedProducts: selectedProducts.map((product) => ({
          productName: product.productName,
          count: product.count,
          availableQuantity: product.count,
          amount: product.amount,
        })),
        totalAmount: totalAmount,
      };

      await apiService.postCall(
        apiPath.apiCreateEmployeeProduct,
        employeeAddedProductData
      );

      const newEmployeeProduct = {
        date: date,
        addedProducts: selectedProducts,
      };

      setEmployeeProduct((prev) => [...prev, newEmployeeProduct]);

      setFilteredProducts((prev) => {
        if (newEmployeeProduct.date === date) {
          return [...prev, ...newEmployeeProduct.addedProducts];
        }
        return prev;
      });

      setSelectedProducts([]);
      setTotalAmount(0);
      getProducts();
      getEmployeeProducts();
    } catch (error) {
      console.error("Error submitting employee product entry:", error.message);
    }
  };

  useEffect(() => {
    const newTotalAmount = selectedProducts.reduce(
      (sum, product) => sum + (product.amount || 0),
      0
    );
    setTotalAmount(newTotalAmount);
  }, [selectedProducts]);

  const handleRemoveProduct = (productId) => {
    const updatedProducts = selectedProducts.filter(
      (product) => product.productId !== productId
    );
    setSelectedProducts(updatedProducts);
    setTotalAmount(
      updatedProducts.reduce((sum, product) => sum + product.amount, 0)
    );
  };

  const flattenedProducts = filteredProducts.reduce((acc, product) => {
    if (product.addedProducts && Array.isArray(product.addedProducts)) {
      return acc.concat(product.addedProducts);
    }
    return acc;
  }, []);

  const handleProductClick = (addedProduct) => {
    const existingProduct = selectedProducts.find(
      (product) => product.productId === addedProduct.productId
    );

    if (existingProduct) {
      const updatedProducts = selectedProducts.map((product) =>
        product.productId === addedProduct.productId
          ? {
              ...product,
              count: product.count + addedProduct.count,
              amount:
                (product.count + addedProduct.count) *
                parseFloat(product.wholeSalePrice || 0),
            }
          : product
      );
      setSelectedProducts(updatedProducts);
    } else {
      setSelectedProducts([
        ...selectedProducts,
        {
          productId: addedProduct.productId,
          productName: addedProduct.productName,
          count: addedProduct.count,
          amount: addedProduct.amount,
        },
      ]);
    }

    const newTotalAmount = selectedProducts.reduce(
      (sum, product) => sum + product.amount,
      0
    );
    setTotalAmount(newTotalAmount);
  };

  return (
    <div>
      <form onSubmit={handleEmpAddProduct}>
        <div>
          <label htmlFor="date">{t("addProductForm.date")}</label>
          <input
            type="date"
            id="date"
            value={date}
            onChange={(e) => handleDateChange(e.target.value)}
            required
          />
        </div>

        <div>
          <h3>{t("addProductForm.selectProducts")}</h3>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(190px, 1fr))",
              gap: "10px",
            }}
          >
            {products.map((product) => {
              const isChecked = selectedProducts.some(
                (item) => item.productId === product._id
              );

              return (
                <div key={product._id} className="product-container">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(product)}
                  />
                  <p className="product-name">{product.productName}</p>
                  {isChecked && (
                    <div className="counter-container">
                      <button
                        type="button"
                        onClick={() => handleCountChange(product._id, -1)}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        value={
                          selectedProducts.find(
                            (p) => p.productId === product._id
                          )?.count ?? ""
                        }
                        onChange={(e) =>
                          handleInputChange(product._id, e.target.value)
                        }
                      />
                      <button
                        type="button"
                        onClick={() => handleCountChange(product._id, 1)}
                      >
                        +
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <h3>{t("addProductForm.selectedProducts")}</h3>
          <ul>
            {selectedProducts.map((product) => (
              <li key={product.productId}>
                {product.productName} x {product.count} = ₹
                {Number(product.amount || 0).toFixed(2)}
                <button
                  type="button"
                  onClick={() => handleRemoveProduct(product.productId)}
                  style={{
                    marginLeft: "10px",
                    color: "red",
                    cursor: "pointer",
                    border: "none",
                    background: "transparent",
                  }}
                >
                  ×
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h3>
            {t("addProductForm.totalAmount")}: ₹
            {Number(totalAmount || 0).toFixed(2)}
          </h3>
        </div>

        <button type="submit">{t("addProductForm.submit")}</button>
      </form>

      <div>
        <h3>
          {t("addProductForm.addedProductsOn")}: {date}
        </h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(190px, 1fr))",
            gap: "10px",
          }}
        >
          {filteredProducts.length === 0 || flattenedProducts.length === 0 ? (
            <div>{t("addProductForm.noProductsAdded")}</div>
          ) : (
            flattenedProducts.map((addedProduct, subIndex) => (
              <div
                key={subIndex}
                onClick={() => handleProductClick(addedProduct)}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                    margin: 0,
                  }}
                >
                  <li>
                    {addedProduct.productName} x {addedProduct.count} = ₹
                    {addedProduct.amount.toFixed(2)}
                  </li>
                </ul>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeAddedProducts;
